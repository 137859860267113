import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmDialog from "../../Components/ConfirmDialog";
import Sidebar from "../../Components/Sidebar";
import { Button, Paper, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { numberWithCommas } from "../../actions/createPositionActions"
import XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment';
import { toast } from "react-toastify";
import { payoutState } from "../../CommomMethods/States";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        width: "100%",
        marginBottom: "10px",
    },
    root2: {
        height: "235px",
        overflowY: "auto",
        // marginTop: "10px",
        width: "100%",
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    iconStyle: {
        color: "#820e37",
        cursor: "pointer",
        marginRight: "20px",
        height: "20px",
        width: "20px",
    },
}));
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
export default function GenerateOpPayout() {
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
    });
    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;
    const classes = useStyles();
    const [changeRowValue, setChangeRowValue] = useState([]);
    const [rowSelection, setRowSelection] = useState(false);
    const [dataVal, setDataVal] = useState([]);
    const [backDropOpen, setBackDropOpen] = useState(false);
    const backClasses = backDropStyle();
    const [rows, setRows] = useState([]);
    const [center, setCenter] = useState([]);
    const [month, setMonth] = useState("Select Month");
    const [year, setYear] = useState("Select Year");
    const [monthValue, setmonthValue] = useState();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [doctor, setDoctor] = useState(null);
    const [centerName, setCenterName] = useState([]);
    const [payOutCat, setPayOutCat] = useState([]);
    const [iPDetails, setIPDetails] = useState([]);
    const [oPDetails, setOPDetails] = useState([]);
    const [retainerDetails, setRetainerDetails] = useState([]);
    const [IPPayoutReports, setIPPayoutReports] = useState([]);
    const [OPPayoutReports, setOPPayoutReports] = useState([]);
    const [RetainerPayoutReports, setRetainerPayoutReports] = useState([]);
    const [LOP, setLOP] = useState(0);
    const [otherEarnings, setOtherEarnings] = useState(0);
    const [advance, setAdvance] = useState(0);
    const [deduction, setDeduction] = useState(0);
    const [TDSPerc, setTDSPerc] = useState(0);
    const [TempData, setTempData] = useState([]);
    const [payoutDataStatus, setPayoutDataStatus] = useState([]);
    const [circularLoader, setCirculatLoader] = React.useState(false);
    const [payoutReq, setPayoutReq] = useState([]);
    const [OPBillData, setOPBillData] = useState([]);
    const [IPBillData, setIPBillData] = useState([]);
    const [OPCalculatedData, setOPCalculatedData] = useState([]);
    const [IPCalculatedData, setIPCalculatedData] = useState([]);
    const [splitDialog, setSplitDialog] = useState(false);
    const [spitRecord, setSplitRecord] = useState({});
    const [splitNewRec, setSplitNewRec] = useState([]);
    const [rowDetail, setRowDetail] = useState({
        referalFee: "",
    });
    const [openr, setOpenr] = React.useState(false);
    const [payoutCycle, setPayoutCycle] = React.useState(false);
    const [mainTab, setMainTab] = React.useState(user.user.roles == 7 ? "Payout Calculation" : "Payout Reports");
    const [calculationTab, setCalculationTab] = React.useState("");
    const [payoutRequestCalculationTab, setPayoutRequestCalculationTab] = React.useState("");
    const [doctorPayoutCalculationTab, setDoctorPayoutCalculationTab] = React.useState("");
    const [doctorPayoutAdjustmentCalculationTab, setDoctorPayoutAdjustmentCalculationTab] = React.useState("");

    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();

    // const payoutCycles = [...Array(currentDate > 21 ? currentMonth : currentMonth > 0 ? currentMonth - 1 : currentMonth)].map((d, m) => ({
    //     label: `${new Date().getFullYear()}-${(m + 1) > 9 ? (m + 1) : '0' + (m + 1)}-21/${new Date().getFullYear()}-${(m + 2) > 9 ? (m + 2) : '0' + (m + 2)}-20`,
    //     value: `${new Date().getFullYear()}-${(m + 1) > 9 ? (m + 1) : '0' + (m + 1)}-21/${new Date().getFullYear()}-${(m + 2) > 9 ? (m + 2) : '0' + (m + 2)}-20`
    // }))
    // console.log('chandan',payoutCycles);
    // const payoutCycles = [
    //     {
    //         label: "2024-02-21/2024-03-20",
    //         value: "2024-02-21/2024-03-20"
    //     },
    //     {
    //         label: "2024-03-21/2024-04-20",
    //         value: "2024-03-21/2024-04-20"
    //     },
    //     {
    //         label: "2024-04-21/2024-05-20",
    //         value: "2024-04-21/2024-05-20"
    //     },
    // ]

    const generateBillCyclesFromLastYear = (year = (new Date).getFullYear -1) => {
        const cycles = [];
        // Start date is 21st of January of the previous year
        const currentDate = new Date();
        const lastYear = year;
        let cycleStartDate = new Date(lastYear, 0, 21); // January 21 of the previous year
        if(currentDate.getDate() <= 20){
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        if (currentDate.getDate() > 20) {
            currentDate.setDate(20);
        }
        // Loop until the current date
        while (cycleStartDate <= currentDate) {
            // Set the start date of the cycle (21st of the current month)
            const startDate = new Date(cycleStartDate);   
            // Set the end date of the cycle (20th of the next month)
            const endDate = new Date(cycleStartDate);
            endDate.setMonth(cycleStartDate.getMonth() + 1);
            endDate.setDate(20);
            // Add the cycle to the array (as a string in yyyy-mm-dd format)
            cycles.push({
                label: `${startDate.toLocaleDateString('en-CA').split('T')[0]}/${endDate.toLocaleDateString('en-CA').split('T')[0]}`,
                value: `${startDate.toLocaleDateString('en-CA').split('T')[0]}/${endDate.toLocaleDateString('en-CA').split('T')[0]}`,
            });
            // Move to the next cycle (next month 21st)
            cycleStartDate.setMonth(cycleStartDate.getMonth() + 1);
            cycleStartDate.setDate(21);
        }
        return cycles;
    }
    const payoutCycles = generateBillCyclesFromLastYear(2024);
    
    useEffect(() => {
        axios.get(APIURL() + `billings/payoutCategoryDetails`)
            .then((res) => {
                setPayOutCat(res.data.data)
            })
        axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
            .then((res) => res.data)
            .then((rows) => {
                console.log(rows, "2323");
                // setCenterName(rows.data.filter(ele => ele.code == 'PPS' || ele.code == 'FPR' || ele.code == 'HTC'));
                setCenterName(rows.data.filter(ele => ele.status == 1));
            });
        if (user.user.roles == 2 || user.user.roles == 1) {
        }
        getPayoutReq()
        console.log(user, 'userData')
    }, []);

    console.log(centerName, "CENTERNAME");
    function selectedMonth(e) {
        const { name, value } = e.target;
        setMonth(value);
        if (value === "JANUARY") {
            setmonthValue(1)
        }
        if (value === "FEBRUARY") {
            setmonthValue(2)
        }
        if (value === "MARCH") {
            setmonthValue(3)
        }
        if (value === "APRIL") {
            setmonthValue(4)
        }
        if (value === "MAY") {
            setmonthValue(5)
        }
        if (value === "JUNE") {
            setmonthValue(6)
        }
        if (value === "JULY") {
            setmonthValue(7)
        }
        if (value === "AUGUST") {
            setmonthValue(8)
        }
        if (value === "SEPTEMBER") {
            setmonthValue(9)
        }
        if (value === "OCTOBER") {
            setmonthValue(10)
        }
        if (value === "NOVEMBER") {
            setmonthValue(11)
        }
        if (value === "DECEMBER") {
            setmonthValue(12)
        }
    }

    function selectedYear(e) {
        const { name, value } = e.target;
        setYear(value);
    }
    function selectedCenter(e) {
        const { name, value } = e.target;
        setCenter(value);
    }

    const generatePay = async () => {
        let IPDoctorDetails = []
        let OPDoctorDetails = []
        let IpAPIResp = ""
        let OPAPIResp = ""
        var regions = '';
        if (center == '' || startDate == '' || endDate == '') {
            await setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle: "Please Select all the required details first",
            });
            return;
        }
        let tempData = (payOutCat).filter((el) => el.siteCode == center)
        console.log(tempData, "tempData-------")
        console.log(user.user, center, year, month, "user.user.user")
        await setBackDropOpen(true);
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/OP`)
            .then((res) => res.data)
            .then(async (rows) => {
                console.log(rows, "rows -OP")
                setOPDetails(rows)
                await rows.map((elem) => {
                    if (elem.ConductedBy) {
                        var docname = elem.ConductedBy ? (elem.ConductedBy).trim() : '';
                        var prefixDocname = docname.includes('dr. ') ? docname.replace("dr. ", "") : docname.includes('dr.') ? docname.replace("dr.", "") : docname
                        prefixDocname = docname.includes('Dr. ') ? docname.replace("Dr. ", "") : docname.includes('Dr.') ? docname.replace("Dr.", "") : docname
                        prefixDocname = prefixDocname.toLowerCase().trim()
                        if (tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID)) {
                            tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID).OPPayout = parseFloat(tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID).OPPayout) + parseFloat(elem.doctorFee)
                        }
                    }
                })
            });
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/IP`)
            .then((res) => res.data)
            .then(async (rows) => {
                setIPDetails(rows)
                console.log(rows, "rows -IP")
                await rows.map((elem) => {
                    if (elem.ConductedBy) {
                        var docname = elem.ConductedBy ? (elem.ConductedBy).trim() : '';
                        var prefixDocname = docname.includes('dr. ') ? docname.replace("dr. ", "") : docname.includes('dr.') ? docname.replace("dr.", "") : docname
                        prefixDocname = docname.includes('Dr. ') ? docname.replace("Dr. ", "") : docname.includes('Dr.') ? docname.replace("Dr.", "") : docname
                        prefixDocname = prefixDocname.toLowerCase().trim()
                        if (tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID)) {
                            tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID).IPPayout = parseFloat(tempData.find(item => (item.name).includes(prefixDocname) || (item.email) == elem.CONDUCTED_BY_LOGIN_ID).IPPayout) + parseFloat(elem.doctorFee)
                        }
                    }
                })
            });
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/Retainer`)
            .then((res) => res.data)
            .then(async (rows) => {
                setRetainerDetails(rows)
                console.log(rows, "rows -Retainer")
                await rows.map((elem) => {
                    if (tempData.find(item => (item.name).includes(elem.ConductedBy))) {
                        tempData.find(item => (item.name).includes(elem.ConductedBy)).Payout = parseFloat(elem.doctorFee)
                    }
                })
            });
        await tempData.map(item => {
            if (item.category == "Full Time Consultant") {
                let numOfDays = new Date(year, monthValue, 0).getDate()
                let fixedAmtGross = (((parseFloat(item.fixedAmount) / numOfDays) * (numOfDays - LOP)) + otherEarnings) - deduction
                let fixedAmtNet = (fixedAmtGross - ((fixedAmtGross * TDSPerc) / 100)) - advance
                item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout) + parseFloat(fixedAmtNet)
            } else if (item.category == "Visiting Consultant") {
                item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout)
            } else if (item.category == "Retainer") {
                item.Payout = item.Payout
            } else if (item.category == "Minimum Guarentee(MG)") {
                if ((parseFloat(item.IPPayout) + parseFloat(item.OPPayout)) > item.minguranteepermonth) {
                    item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout)
                } else {
                    item.Payout = item.minguranteepermonth
                }
            }
            item.OPDRevenue = 0
            item.IPDRevenue = 0
            return item
        })
        setTempData(tempData)
        setBackDropOpen(false);
        getPayoutStatus()
    }

    const toggleHoldModal = (row) => {
        // setDocData(row)
        // setOpenHoldModal(true)

    }


    const viewPayout = async (param) => {
        var center = ""
        var startDate = ""
        var endDate = ""
        if (param.center) {
            center = param.center
        }
        if (param.startDate) {
            startDate = param.startDate
        }
        if (param.endDate) {
            endDate = param.endDate
        }
        let IPDoctorDetails = []
        let OPDoctorDetails = []
        let IpAPIResp = ""
        let OPAPIResp = ""
        var regions = '';

        // needs o modify later
        // if (center == '' || startDate == '' || endDate == '') {
        if (false) {
            await setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle: "Please Select all the required details first",
            });
            return;
        }
        var seen = Object.create(null)
        let tempData = (payOutCat).filter((el) => {
            if (el.siteCode == center) {
                var key = ['siteCode', 'email'].map(k => el[k]).join('|');
                if (!seen[key]) {
                    seen[key] = true;
                    return true;
                }
            }
        })
        console.log(tempData, "tempData-------")
        console.log(user.user, center, year, month, "user.user.user")
        await setBackDropOpen(true);
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/OP`)
            .then((res) => res.data)
            .then(async (rows) => {
                console.log(rows, "rows -OP")
                setOPDetails(rows)
                await rows.map((elem) => {
                    if (elem.ConductedBy) {
                        var docname = elem.ConductedBy ? (elem.ConductedBy).trim() : '';
                        var prefixDocname = docname.includes('dr. ') ? docname.replace("dr. ", "") : docname.includes('dr.') ? docname.replace("dr.", "") : docname
                        prefixDocname = docname.includes('Dr. ') ? docname.replace("Dr. ", "") : docname.includes('Dr.') ? docname.replace("Dr.", "") : docname
                        prefixDocname = prefixDocname.toLowerCase().trim()
                        console.log(prefixDocname, tempData.find(item => (item.name).includes(prefixDocname) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())), 'OPD')
                        if (tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim()))) {
                            tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPPayout = parseFloat(tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPPayout) + parseFloat(elem.doctorFee)
                            tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPDRevenue = parseFloat(tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPDRevenue) + parseFloat(elem.Net)
                        }
                        // if (tempData.find(item => (prefixDocname.toLowerCase()).includes(item.name) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim()))) {
                        //     tempData.find(item => (prefixDocname.toLowerCase()).includes(item.name) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPPayout = parseFloat(tempData.find(item => (prefixDocname.toLowerCase()).includes(item.name) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPPayout) + parseFloat(elem.doctorFee)
                        //     tempData.find(item => (prefixDocname.toLowerCase()).includes(item.name) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPDRevenue = parseFloat(tempData.find(item => (prefixDocname.toLowerCase()).includes(item.name) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).OPDRevenue) + parseFloat(elem.Net)
                        // }
                    }
                })
            });
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/IP`)
            .then((res) => res.data)
            .then(async (rows) => {
                setIPDetails(rows)
                console.log(rows, "rows -IP")
                await rows.map((elem) => {
                    if (elem.ConductedBy) {
                        var docname = elem.ConductedBy ? (elem.ConductedBy).trim() : '';
                        var prefixDocname = docname.includes('dr. ') ? docname.replace("dr. ", "") : docname.includes('dr.') ? docname.replace("dr.", "") : docname
                        prefixDocname = docname.includes('Dr. ') ? docname.replace("Dr. ", "") : docname.includes('Dr.') ? docname.replace("Dr.", "") : docname
                        prefixDocname = prefixDocname.toLowerCase().trim()
                        console.log(prefixDocname, tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())), 'IPD')
                        // if (tempData.find(item => (prefixDocname.toLowerCase()).includes((item.name).toLowerCase().trim()) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim()))) {
                        //     tempData.find(item => (prefixDocname.toLowerCase()).includes((item.name).toLowerCase().trim()) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPPayout = parseFloat(tempData.find(item => (prefixDocname.toLowerCase()).includes((item.name).toLowerCase().trim()) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPPayout) + parseFloat(elem.doctorFee)
                        //     tempData.find(item => (prefixDocname.toLowerCase()).includes((item.name).toLowerCase().trim()) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPDRevenue = parseFloat(tempData.find(item => (prefixDocname.toLowerCase()).includes((item.name).toLowerCase().trim()) || (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPDRevenue) + parseFloat(elem.Net)
                        // }
                        if (tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim()))) {
                            tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPPayout = parseFloat(tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPPayout) + parseFloat(elem.doctorFee)
                            tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPDRevenue = parseFloat(tempData.find(item => (item.email && elem.CONDUCTED_BY_LOGIN_ID && (item.email).toLowerCase().trim() == (elem.CONDUCTED_BY_LOGIN_ID).toLowerCase().trim())).IPDRevenue) + parseFloat(elem.Net)
                        }
                    }
                })
            });
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/Retainer`)
            .then((res) => res.data)
            .then(async (rows) => {
                setRetainerDetails(rows)
                console.log(rows, "rows -Retainer")
                await rows.map((elem) => {
                    if (tempData.find(item => (item.name).includes(elem.ConductedBy))) {
                        tempData.find(item => (item.name).includes(elem.ConductedBy)).Payout = parseFloat(elem.doctorFee)
                        tempData.find(item => (item.name).includes(elem.ConductedBy)).netPay = parseFloat(elem.doctorFee)
                    }
                })
            });
        await axios
            .get(APIURL() + `billings/get-payout-details-doctor-wise/${center === "Select Center" || center === "" ? 0 : center}/${startDate === "" ? 0 : startDate}/${endDate === "" ? 0 : endDate}/Consolidate`)
            .then((res) => res.data)
            .then(async (rows) => {
                console.log(rows, "rows -OP")
                await rows.map((elem) => {
                    var docId = elem.doctorId
                    if (tempData.find(item => item.doctorId == docId)) {
                        tempData.find(item => item.doctorId == docId).otherEarning = elem.otherEarning
                        tempData.find(item => item.doctorId == docId).otherDeduction = elem.otherDeduction
                        tempData.find(item => item.doctorId == docId).otherEarningRemarks = elem.otherEarningRemarks
                        tempData.find(item => item.doctorId == docId).otherDeductionRemarks = elem.otherDeductionRemarks
                        tempData.find(item => item.doctorId == docId).hold = elem.hold
                        tempData.find(item => item.doctorId == docId).netPay = elem.netPay
                    }
                })
            });
        console.log(tempData, 'alltempdata')
        await tempData.map(item => {
            var date1 = new Date(item.doj);
            var date2 = new Date(endDate);
            var timeDiff = Math.abs(date2.getTime() - date1.getTime());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            let numOfDays = (new Date(new Date(startDate).getFullYear(), (new Date(startDate).getMonth()) + 1, 0).getDate())
            item.otherEarning = item.otherEarning ? item.otherEarning : 0
            item.otherDeduction = item.otherDeduction ? item.otherDeduction : 0
            item.otherEarningRemarks = item.otherEarningRemarks ? item.otherEarningRemarks : ''
            item.otherDeductionRemarks = item.otherDeductionRemarks ? item.otherDeductionRemarks : ''
            item.startDate = startDate
            item.endDate = endDate
            item.center = center
            item.hold = item.hold ? item.hold : 0
            if (item.category == "Full Time Consultant") {
                // let fixedAmtGross = (((parseFloat(item.fixedAmount) / numOfDays) * (numOfDays - LOP)) + otherEarnings) - deduction
                // let fixedAmtNet = (fixedAmtGross - ((fixedAmtGross * TDSPerc) / 100)) - advance
                // item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout)
                if (item.doj > startDate) {
                    item.Payout = parseFloat((parseFloat((parseFloat(item.fixedAmount) / numOfDays) * (diffDays + 1))).toFixed())
                } else {
                    item.Payout = item.fixedAmount
                }
            } else if (item.category == "Visiting Consultant") {
                item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout)
            } else if (item.category == "Retainer") {
                item.Payout = item.Payout
            } else if (item.category == "Minimum Guarentee(MG)") {
                if (item.doj > startDate) {
                    item.Payout = parseFloat((parseFloat((parseFloat(item.minguranteepermonth) / numOfDays) * (diffDays + 1))).toFixed())
                } else {
                    item.Payout = item.minguranteepermonth
                }
                // if ((parseFloat(item.IPPayout) + parseFloat(item.OPPayout)) > item.minguranteepermonth) {
                //     item.Payout = parseFloat(item.IPPayout) + parseFloat(item.OPPayout)
                // } else {
                //     item.Payout = item.minguranteepermonth
                // }
            }
            item.netPay = item.netPay ? item.netPay : item.netPay == 0 ? item.netPay : item.Payout
            return item
        })
        console.log(tempData, 'calculatedtempData')
        setTempData(tempData)
        setBackDropOpen(false);
        getPayoutStatus({
            center: center,
            startDate: startDate,
            endDate: endDate
        })
    }

    var currentdate = new Date().toJSON().slice(0, 10);
    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        if (name == "StartDate") {
            setStartDate(value)
            setEndDate(value);
        }
        if (name == "EndDate") {
            setEndDate(value)
        }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setBackDropOpen(true);
    //         axios.get(APIURL() + 'billings/Payout/generatedOpPayout', { params: { center: center, month: month, year: year } })
    //             .then((res) => res.data)
    //             .then((rows) => {
    //                 console.log(rows, "0002");
    //                 setDataVal(rows.data);
    //                 setBackDropOpen(false);
    //             });
    //     }, 4000);

    // }, [center, month, year])


    const downloadExcel = async () => {
        var payoutData = [...TempData]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                "SlNo": count,
                "Doctor Name": row.name.includes('Dr. ') ? row.name : "Dr. " + row.name,
                "Doctor LT ID": row.email,
                "PAN": row.panno,
                "D365 ID": row.d365_id,
                "Date of Joining": row.doj,
                "Previous Agreement Date": row.doj,
                "Current Agreement Date": row.doj,
                "Doctor Type": row.category,
                "Center": center,
                "MG / Month": row.minguranteepermonth ? parseFloat(parseFloat(row.minguranteepermonth).toFixed()) : 0,
                "Fixed": row.fixedAmount ? parseFloat(parseFloat(row.fixedAmount).toFixed()) : 0,
                "OPD": row.OPPayout ? parseFloat(parseFloat(row.OPPayout).toFixed()) : 0,
                "IPD": row.IPPayout ? parseFloat(parseFloat(row.IPPayout).toFixed()) : 0,
                "IPD Revenue": row.IPDRevenue ? parseFloat(parseFloat(row.IPDRevenue).toFixed()) : 0,
                "IPD Revenue %": (row.IPDRevenue ? ((Math.round((row.IPPayout / row.IPDRevenue) * 100))) + " %" : 0),
                "OPD Revenue": row.OPDRevenue ? parseFloat(parseFloat(row.OPDRevenue).toFixed()) : 0,
                "OPD Revenue %": (row.OPDRevenue ? ((Math.round((row.OPPayout / row.OPDRevenue) * 100))) + " %" : 0),
                "Calculated Payout": row.Payout ? parseFloat(parseFloat(row.Payout).toFixed()) : 0,
                "Addition": row.otherEarning ? parseFloat(parseFloat(row.otherEarning).toFixed()) : 0,
                "Addition Remarks": row.otherEarningRemarks,
                "Deduction": row.otherDeduction ? parseFloat(parseFloat(row.otherDeduction).toFixed()) : 0,
                "Deduction Remarks": row.otherDeductionRemarks,
                "Doctor Final Payout": row.netPay ? parseFloat(parseFloat(row.netPay).toFixed()) : 0,
                "MG Earning": 0,
            }
            if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                obj["MG Earning"] = parseFloat(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
            } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                obj["MG Earning"] = "-" + parseFloat((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
            } else {
                obj["MG Earning"] = 0
            }
            count++
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Payout-Report_" + center + "_" + startDate + "_" + endDate + ".xlsx")
    }
    const downloadRemarksExcel = async () => {
        var payoutData = [...TempData.filter(ele => ele.otherDeductionRemarks !== '' || ele.otherEarningRemarks !== '')]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                "SlNo": count,
                "Doctor Name": row.name.includes('Dr. ') ? row.name : "Dr. " + row.name,
                "Doctor LT ID": row.email,
                "PAN": row.panno,
                "D365 ID": row.d365_id,
                "Date of Joining": row.doj,
                "Previous Agreement Date": row.doj,
                "Current Agreement Date": row.doj,
                "Doctor Type": row.category,
                "Center": center,
                "MG / Month": row.minguranteepermonth ? parseFloat(parseFloat(row.minguranteepermonth).toFixed()) : 0,
                "Fixed": row.fixedAmount ? parseFloat(parseFloat(row.fixedAmount).toFixed()) : 0,
                "OPD": row.OPPayout ? parseFloat(parseFloat(row.OPPayout).toFixed()) : 0,
                "IPD": row.IPPayout ? parseFloat(parseFloat(row.IPPayout).toFixed()) : 0,
                "IPD Revenue": row.IPDRevenue ? parseFloat(parseFloat(row.IPDRevenue).toFixed()) : 0,
                "IPD Revenue %": (row.IPDRevenue ? ((Math.round((row.IPPayout / row.IPDRevenue) * 100))) + " %" : 0),
                "OPD Revenue": row.OPDRevenue ? parseFloat(parseFloat(row.OPDRevenue).toFixed()) : 0,
                "OPD Revenue %": (row.OPDRevenue ? ((Math.round((row.OPPayout / row.OPDRevenue) * 100))) + " %" : 0),
                "Calculated Payout": row.Payout ? parseFloat(parseFloat(row.Payout).toFixed()) : 0,
                "Addition": row.otherEarning ? parseFloat(parseFloat(row.otherEarning).toFixed()) : 0,
                "Addition Remarks": row.otherEarningRemarks,
                "Deduction": row.otherDeduction ? parseFloat(parseFloat(row.otherDeduction).toFixed()) : 0,
                "Deduction Remarks": row.otherDeductionRemarks,
                "Doctor Final Payout": row.netPay ? parseFloat(parseFloat(row.netPay).toFixed()) : 0,
                "MG Earning": 0,
            }
            if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                obj["MG Earning"] = parseFloat(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
            } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                obj["MG Earning"] = "-" + parseFloat((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
            } else {
                obj["MG Earning"] = 0
            }
            count++
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Payout-Report_" + center + "_" + startDate + "_" + endDate + ".xlsx")
    }

    const downloadMGExcel = async () => {
        var payoutData = [...TempData.filter(item => item.category == "Minimum Guarentee(MG)")]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                "SlNo": count,
                "Doctor Name": row.name.includes('Dr. ') ? row.name : "Dr. " + row.name,
                "Doctor LT ID": row.email,
                "PAN": row.panno,
                "D365 ID": row.d365_id,
                "mgTerm": row.panno,
                "Date of Joining": row.doj,
                "Previous Agreement Date": row.doj,
                "Current Agreement Date": row.doj,
                "Doctor Type": row.category,
                "Center": center,
                "MG / Month": row.minguranteepermonth ? parseFloat(parseFloat(row.minguranteepermonth).toFixed()) : 0,
                "Payout": row.Payout ? parseFloat(parseFloat(row.Payout).toFixed()) : 0,
                "MG Earning": 0,
            }
            if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                obj["MG Earning"] = parseFloat(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
            } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                obj["MG Earning"] = "-" + parseFloat((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
            } else {
                obj["MG Earning"] = 0
            }
            count++
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "MG")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "MG-Payout-Report_" + center + "_" + startDate + "_" + endDate + ".xlsx")
    }

    const downloadDoctorIPReport = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...iPDetails]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "IPNo": row.ip,
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Age": row.age,
                "Gender": row.gender,
                "Admitting Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "Admit Group": row.admitGroup,
                "Admission Purpose": row.admissionPurpose,
                "LOS(InDays)": row.LOS,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Bed Category": row.bedCategory,
                "Bill Type": row.billType,
                "Package Name": row.packageName,
                "Package Amount": row.PackageAmount ? parseFloat(parseFloat(row.PackageAmount).toFixed()) : 0,
                "Category": row.insuranceCategory,
                "TPA": row.tpaName,
                "Insurance": row.insurance,
                "Corporate Name": row.organisation,
                "User": row.User,
                "Month": row.Month,
                "ipGroup": row.ipGroup,
                "Sub Category": row.ipSubGroup,
                "ERP LOS": row.erpLos,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Lab": row.Lab ? parseFloat(parseFloat(row.Lab).toFixed()) : 0,
                "Radiology": row.Radiology ? parseFloat(parseFloat(row.Radiology).toFixed()) : 0,
                "Other Deduction": row.otherDeduction ? parseFloat(parseFloat(row.otherDeduction).toFixed()) : 0,
                "Epidural Charges": row.epiduralCharges ? parseFloat(parseFloat(row.epiduralCharges).toFixed()) : 0,
                "Safety Consumable": row.safetyConsumable ? parseFloat(parseFloat(row.safetyConsumable).toFixed()) : 0,
                "Net Bill": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Package Inclusion": row.packageInclusion,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Qty,
                "Amount": row.amount ? parseFloat(parseFloat(row.amount).toFixed()) : 0,
                "Ratio": row.ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "IP-Payout-Report-" + doctor.name + "_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadDoctorOPReport = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...oPDetails]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Ordering Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "Package Overrun": row.PackageOverrun ? parseFloat(parseFloat(row.PackageOverrun).toFixed()) : 0,
                "User": row.User,
                "Month": row.Month,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Tax": row.Lab,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Qty": row.Count ? parseFloat(parseFloat(row.Count).toFixed()) : 0,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "Exception": row.vlookup,
            }
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "OP-Payout-Report-" + doctor.name + "_" + center + "_" + StartDate + "_" + EndDate + ".xlsx")
    }

    const downloadRetainerExcel = async () => {
        var payoutData = [...RetainerPayoutReports]
        var count = 1
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                "SlNo": count,
                "D365 ID": row.d365_id,
                "Doctor Name": row.ConductedBy,
                "PAN": row.panno,
                "Date of Joining": row.doj,
                "Doctor Email": row.email,
                "Center": row.Location,
                "Nature": row.doctorType,
                "Doctor Amount": row.fixedAmount ? parseFloat(row.fixedAmount).toFixed() : 0,
                "Total Days": row.totalDays,
                "LOP": row.LOS ? row.LOS : 0,
                "LOP Remarks": row.LOPRemarks,
                "OT": row.OT ? row.OT : 0,
                "OT Remarks": row.OTRemarks,
                "Other Deduction": row.otherDeduction ? parseFloat(row.otherDeduction) : 0,
                "Other Deduction Remarks": row.otherDeductionRemarks,
                "Other Earning": row.otherEarning ? parseFloat(row.otherEarning) : 0,
                "Other Earning Remarks": row.otherEarningRemarks,
                "Gross Earning": row.doctorFee ? parseFloat(row.doctorFee) : 0,
                "TDS(%)": 10,
                "Net Pay": row.NetPay ? parseFloat(row.NetPay) : 0,
            }
            count++
            return obj
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Ratianer-Payout_" + startDate + "_" + endDate + ".xlsx")
    }

    const getDoctorData = async (row) => {
        setDoctor(null)
        setIPDetails([])
        setOPDetails([])
        setRetainerDetails([])
        await axios
            .post(APIURL() + `billings/get-payout-details-doctor-wise/${center}/${startDate}/${endDate}`, {
                doctor: row
            })
            .then((res) => res.data)
            .then(async (rows) => {
                console.log(rows, "rows -Retainer")
                setIPDetails(rows.ipData)
                setOPDetails(rows.opData)
                setRetainerDetails(rows.retainerData)
                setDoctor(row)
            });
    }

    const getPayoutStatus = async (params = {}) => {
        // var data = {
        //     center: params.center ? params.center : center,
        //     startDate: params.startDate ? params.startDate : startDate,
        //     endDate: params.endDate ? params.endDate : endDate,
        // }
        // hard coded for retainer need to change later
        var data = {
            center: params.center ? params.center : center,
            startDate: params.startDate ? params.startDate : startDate,
            endDate: params.endDate ? params.endDate : endDate,
            // patientType: 'Retainer'
        }
        await axios.post(APIURL() + 'billings/payout-status', data)
            .then(async (result) => {
                console.log(result, 'payoutDataStatus')
                setPayoutDataStatus(result.data.data)
            })
    }

    const sendToCH = async () => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: payoutState.init.state,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.ch_approval.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.ch_approval.message);
            await setCirculatLoader(false);
            await getPayoutStatus()
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }
    const sendBackToCH = async (currentAction) => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: currentAction,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.ch_approval.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.dp_rejected.currmessage);
            await setCirculatLoader(false);
            await getPayoutStatus()
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }

    const sendBackToDP = async (currentAction) => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: currentAction,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.init.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.dp_rejected.currmessage);
            await setCirculatLoader(false);
            await getPayoutStatus()
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }

    const sendToCOO = async () => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: payoutState.dp_reviewd.state,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.rd_approval.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.rd_approval.message);
            await setCirculatLoader(false);
            await getPayoutStatus({
                center: center,
                startDate: startDate,
                endDate: endDate
            })
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }

    const sendToDPReview = async () => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: payoutState.ch_approved.state,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.dp_team_second_approval.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.dp_team_second_approval.message);
            await setCirculatLoader(false);
            await getPayoutStatus({
                center: center,
                startDate: startDate,
                endDate: endDate
            })
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }

    const sendToDP = async () => {
        setCirculatLoader(true);
        console.log(Object.values(payoutDataStatus), 'payoutDataStatus')
        var statusData = Object.values(payoutDataStatus)
        if (statusData && statusData.length == 3) {
            for (let i = 0; i < statusData.length; i++) {
                const element = statusData[i];
                var data = {
                    center: element.patientType == "Retainer" ? center : element.center,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    year: element.year,
                    month: element.month,
                    currentAction: payoutState.rd_approved.state,
                    currentActionBy: user.user.id,
                    role: user.user.roles,
                    request_id: element.request_id,
                    nextAction: payoutState.dp_team_final_reviw.state,
                    status: 1,
                    patientType: element.patientType,
                }
                await axios.post(APIURL() + 'billings/send-for-approval', data)
            }
            await toast.success(payoutState.rd_approved.currmessage);
            await setCirculatLoader(false);
            await getPayoutStatus({
                center: center,
                startDate: startDate,
                endDate: endDate
            })
        } else {
            setCirculatLoader(false);
            toast.error("Something went wrong. Please try again");
        }
    }

    const getPayoutReq = async () => {
        await axios
            .post(APIURL() + `billings/get-payout-requests`, { userId: user.user.id })
            .then((res) => res.data)
            .then(async (rows) => {
                const result = rows.data
                console.log(result, 'getPayoutReq')
                var payData = []
                for (let i = 0; i < result.length; i++) {
                    if (!payData.find((ele) => ele.startDate == result[i].startDate && ele.endDate == result[i].endDate && ele.center == result[i].center)) {
                        await payData.push(result[i])
                    }
                    // for (let j = 0; j < result[i].length; j++) {
                    //     if (result[i][j].patientType === "Retainer") {
                    //         payData.push(result[i][j])
                    //     }
                    // }
                }
                console.log(payData, 'payout')
                await setPayoutReq(payData);
            });
    }

    const getPayoutReports = async (row) => {
        await setCenter(row.center);
        await setStartDate(row.startDate);
        await setEndDate(row.endDate);
        await setCirculatLoader(true);
        console.log(row)
        var seen = Object.create(null)
        let tempData = (payOutCat).filter((el) => {
            if (el.siteCode == row.center) {
                var key = ['siteCode', 'email'].map(k => el[k]).join('|');
                if (!seen[key]) {
                    seen[key] = true;
                    return true;
                }
            }
        })
        try {
            await axios
                .get(APIURL() + `billings/get-payout-details/${row.center === "Select Center" || row.center === "" ? 0 : row.center}/${row.startDate === "" ? 0 : row.startDate}/${row.endDate === "" ? 0 : row.endDate}/IP`)
                .then((res) => res.data)
                .then(async (rows) => {
                    await setIPPayoutReports(rows);
                });
        } catch (err) {
            console.log(err)
        }
        try {
            await axios
                .get(APIURL() + `billings/get-payout-details/${row.center === "Select Center" || row.center === "" ? 0 : row.center}/${row.startDate === "" ? 0 : row.startDate}/${row.endDate === "" ? 0 : row.endDate}/OP`)
                .then((res) => res.data)
                .then(async (rows) => {
                    await setOPPayoutReports(rows);
                });
        } catch (err) {
            console.log(err)
        }
        try {
            await axios
                .get(APIURL() + `billings/get-payout-details/${row.center === "Select Center" ? 0 : row.center}/${row.startDate === "" ? 0 : row.startDate}/${row.endDate === "" ? 0 : row.endDate}/Retainer`)
                .then((res) => res.data)
                .then(async (rows) => {
                    await rows.forEach((elem) => {
                        var doctor = tempData.find(item => (item.email) == elem.CONDUCTED_BY_LOGIN_ID)
                        console.log(doctor, 'retainer doc')
                        if (doctor) {
                            elem.panno = doctor.panno
                            elem.fixedAmount = doctor.fixedAmount
                            elem.doj = doctor.doj
                            elem.d365_id = doctor.d365_id
                            elem.doctorType = doctor.category
                        }
                    })
                    await setRetainerPayoutReports(rows);
                });
        } catch (err) {
            console.log(err)
        }
        await viewPayout({
            center: row.center,
            startDate: row.startDate,
            endDate: row.endDate
        })
        await setCirculatLoader(false);
    }

    const onSplitRow = (row) => {
        setSplitRecord(row);
    };

    const updateBillItemData = async (newData, oldData, condition = '') => {
        console.log(newData, oldData, 'function calls')
        var UpdatededData = newData;
        if (condition == 'conditionally' && newData.commercialTerm && newData.commercialTerm.includes('%')) {
            UpdatededData.doctorFee = parseFloat(parseFloat(newData.doctorAmount) * parseFloat(newData.Ratio)).toFixed()
        }
        var data = {
            payout_detail_id: oldData.id,
            newData: UpdatededData
        }
        await axios.post(APIURL() + 'billings/update-bill-item', data).catch((error) => {
            console.log(error)
        }).then((res) => {
            console.log(res, 'Updated bill item')
            toast.success("Bill item updated successfully");
        })
    }

    const updateDoctorItemData = async (newData, oldData) => {
        console.log(newData, oldData, 'function calls')
        var doctorFee = parseFloat(newData.Payout)
        if (newData.otherDeduction) {
            doctorFee = (parseFloat(doctorFee)) - (parseFloat(newData.otherDeduction))
        }
        if (newData.otherEarning) {
            doctorFee = (parseFloat(doctorFee)) + (parseFloat(newData.otherEarning))
        }
        var data = {
            doctor_payout_id: oldData.id,
            newData: {
                doctorId: newData.doctorId,
                startDate: newData.startDate,
                endDate: newData.endDate,
                center: newData.center,
                VendorAccountNumber: newData.d365_id,
                payouts: newData.Payout,
                netPay: doctorFee,
                otherEarning: newData.otherEarning,
                otherEarningRemarks: newData.otherEarningRemarks,
                otherDeduction: newData.otherDeduction,
                otherDeductionRemarks: newData.otherDeductionRemarks,
            }
        }
        await axios.post(APIURL() + 'billings/update-doctor-payout-item', data).catch((error) => {
            console.log(error)
        }).then((res) => {
            console.log(res, 'Updated Doctor Payout item')
            toast.success("Doctor Payout updated successfully");
        })
    }

    const addRowData = (row) => {
        let rec = {};
        spitRecord.ConductedBy = rowDetail.doctorname;
        spitRecord.Share = rowDetail.ratio;
        rec = {
            ...spitRecord,
            ConductedBy: rowDetail.doctorname,
            Share: rowDetail.ratio,
            Ratio: rowDetail.ratio,
        };
        let newData = [...splitNewRec];
        newData.push(rec);
        newData.forEach((i, index) => {
            console.log(i, index, "record 21");
        });
        rec = {};
        setSplitNewRec(newData);
        // setRowDetail({ doctorname: "", ratio: "", percentage: "" });
        setRowDetail({ referalFee: "" });
    };

    const changeSplitRecord = async () => {
        axios
            .post(APIURL() + "billings/createipSplidRecord", splitNewRec)
            .then(function (response) {
                setSplitDialog(false);
                setSplitRecord({})
                setSplitNewRec([])
                // getRecord(splitNewRec[0].patientType, "normal")
            });
        //createSplidRecord
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setRowDetail({ ...rowDetail, [name]: value });
    };

    const handlerClose = () => {
        setOpenr(false);
    };

    const handleChangeMaintab = (event, newValue) => {
        setMainTab(newValue);
    };

    const handleChangeCalculationTab = (event, newValue) => {
        setCalculationTab(newValue);
    };

    const handleChangePayoutRequestCalculationTab = (event, newValue) => {
        setPayoutRequestCalculationTab(newValue);
        console.log(newValue, 'handleChangePayoutRequestCalculationTab')
    };

    const handleChangeDoctorPayoutCalculationTab = (event, newValue) => {
        setDoctorPayoutCalculationTab(newValue);
    };

    const handleChangeDoctorPayoutAdjustmentCalculationTab = (event, newValue) => {
        setDoctorPayoutAdjustmentCalculationTab(newValue);
    };

    const selecPayoutCycle = (e) => {
        var val = e.target.value
        if (val) {
            var splitPayoutCycle = val.split("/")
            console.log(splitPayoutCycle, 'selecPayoutCycle')
            setStartDate(splitPayoutCycle[0])
            setEndDate(splitPayoutCycle[1])
            setPayoutCycle(val)
        }
    }

    const LoadData = async () => {
        await setCirculatLoader(true);
        var data = {
            Center: center,
            // Year: year,
            // Month: month,
            startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
            endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
        };
        await axios
            .post(
                APIURL() + "billings/ipBillingDetailProcessDump",
                data
            )
            .then((res) => res.data)
            .then((rows) => {
                // console.log(rows, "@!!45");
            });

        await getRecord();
    }

    const getRecord = async () => {
        await setCirculatLoader(true);
        await axios
            .get(APIURL() + `billings/loadipbills/${center}/${year}/${month}/normal/${startDate}/${endDate}`)
            .then((res) => res.data)
            .then((rows) => {
                setIPBillData(rows);
                console.log("\n\n\n\n\n----------@!!45-------\n");
            }).catch((err) => {
                console.log(err, "\n\n\n\n\n----------@!!45-------\n");
            });

        await axios
            .get(APIURL() + `billings/loadbills/${center}/${year}/${month}/${startDate}/${endDate}`)
            .then((res) => res.data)
            .then((rows) => {
                console.log(rows, "@!!45");

                setOPBillData(rows);
            });
        setCalculationTab("IPD")
        await setCirculatLoader(false);
    }



    const downloadIPDPayoutBillingDetails = () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...IPBillData]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "IPNo": row.IPNo,
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Age": row.Age,
                "Gender": row.Gender,
                "Admitting Physician": row.AdmittingPhysician,
                "Specialisation": row.Specialisation,
                "Admit Group": row.AdmitGroup,
                "Admission Purpose": row.AdmissionPurpose,
                "Admission Date": row.AdmissionDate,
                "Admission Time": row.AdmissionTime,
                "Discharge Date": row.DischargeDate,
                "Discharge Time": row.DischargeTime,
                "LOS(InDays)": row.LOSInDays,
                "Ward": row.Ward,
                "Room": row.Room,
                "Bed": row.Bed,
                "Bed Category": row.BedCategory,
                "Bill Type": row.BillType,
                "Pharmacy Total": row.PharmacyTotal ? parseFloat(parseFloat(row.PharmacyTotal).toFixed()) : 0,
                "Total": row.Total ? parseFloat(parseFloat(row.Total).toFixed()) : 0,
                "Pharmacy Return": row.PharmacyReturn ? parseFloat(parseFloat(row.PharmacyReturn).toFixed()) : 0,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Rounding": row.Rounding ? parseFloat(parseFloat(row.Rounding).toFixed()) : 0,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Advance Adjusted": row.AdvanceAdjusted ? parseFloat(parseFloat(row.AdvanceAdjusted).toFixed()) : 0,
                "NetAfter Advance": row.NetAfterAdvance ? parseFloat(parseFloat(row.NetAfterAdvance).toFixed()) : 0,
                "Cash": row.Cash ? parseFloat(parseFloat(row.Cash).toFixed()) : 0,
                "Card": row.Card ? parseFloat(parseFloat(row.Card).toFixed()) : 0,
                "CHQ": row.CHQ ? parseFloat(parseFloat(row.CHQ).toFixed()) : 0,
                "Amount Due": row.AmountDue ? parseFloat(parseFloat(row.AmountDue).toFixed()) : 0,
                "Package Name": row.PackageName,
                "Package Amount": row.PackageAmount ? parseFloat(parseFloat(row.PackageAmount).toFixed()) : 0,
                "Category": row.Category,
                "TPA": row.TPA,
                "Insurance": row.Insurance,
                "Corporate Name": row.CorporateName,
                "Auth Code": row.AuthCode,
                "Approved Amount": row.ApprovedAmount ? parseFloat(parseFloat(row.ApprovedAmount).toFixed()) : 0,
                "User": row.User,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "ipGroup": row.ipGroup,
                "ipSubGroup": row.ipSubGroup,
                "ERP LOS": row.erpLos,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Ip-Payout-Billing-Details_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadOPDPayoutBillingDetails = () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...OPBillData]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "Patient Name": row.PatientName,
                "Bill Time": row.BillTime,
                "Bill No": row.BillNo ? row.BillNo : "",
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Admin Fee": row.AdminFee ? parseFloat(parseFloat(row.AdminFee).toFixed()) : 0,
                "Item Group Name": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Tax": row.Tax ? parseFloat(parseFloat(row.Tax).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Share": row.Share,
                "Ordering Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "ConductedBy": row.ConductedBy,
                "User": row.User,
                "Location": row.Center,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "OP-Payout-Billing-Details")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "OP-Payout-Billing-Details_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const initiateIPCalculation = async (input) => {
        await setCirculatLoader(true);
        await axios
            .post(APIURL() + `billings/initiateCalculation/${center}/${year}/${month}/${startDate}/${endDate}`, {
                role: user.user.roles,
                user_id: user.user.id,
                // request_id: payoutDataStatus.request_id,
                request_id: 1,
                nextAction: input && input.nextActionVal ? input.nextActionVal : 'Initiate',
                "payoutData": input && input.payoutData ? input.payoutData : [],
                "doctorDataResult": input && input.doctorDataResult ? input.doctorDataResult : [],
                "pnpData": input && input.pnpData ? input.pnpData : [],
            })
            .then(async (response) => {
                console.log(response, "initiatecalc");
                if (response.data.nextAction !== 'Completed') {
                    initiateIPCalculation({ "nextActionVal": response.data.nextAction, "payoutData": response.data.data, "doctorDataResult": response.data.doctorDataResult, "pnpData": response.data.pnpData })
                } else {
                    await setIPCalculatedData(response.data.data);
                    await setCirculatLoader(false);
                    getPayoutStatus()
                }
            });
    };

    const initiateOPCalculation = async (input) => {
        await setCirculatLoader(true);
        await axios.post(APIURL() + `billings/loadbills/${center}/${year}/${month}/${startDate}/${endDate}/calcPayByMonth`, {
            role: user.user.roles,
            user_id: user.user.id,
            nextAction: input && input.nextAction ? input.nextAction : 'Initiate',
        })
            .then((res) => res.data)
            .then(async (rows) => {
                if (rows.nextAction !== 'Completed') {
                    initiateOPCalculation({ nextAction: rows.nextAction })
                } else {
                    await setCirculatLoader(false);
                    console.log(rows.data, "#########");
                    await setOPCalculatedData(rows.data);
                    getPayoutStatus()
                }
                // await setDataVal(rows.data);
                // await setBackDropOpen(false);
            });
    }

    const downloadIPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...IPCalculatedData]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "IPNo": row.ip,
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Age": row.age,
                "Gender": row.gender,
                "Admitting Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "Admit Group": row.admitGroup,
                "Admission Purpose": row.admissionPurpose,
                // "Admission Date": row.AdmissionDate,
                // "Discharge Date": row.DischargeDate,
                "LOS(InDays)": row.LOS,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Bed Category": row.bedCategory,
                "Bill Type": row.billType,
                "Package Name": row.packageName,
                "Package Amount": row.PackageAmount ? parseFloat(parseFloat(row.PackageAmount).toFixed()) : 0,
                "Category": row.insuranceCategory,
                "TPA": row.tpaName,
                "Insurance": row.insurance,
                "Corporate Name": row.organisation,
                "User": row.User,
                "Month": row.Month,
                "ipGroup": row.ipGroup,
                "Sub Category": row.ipSubGroup,
                "ERP LOS": row.erpLos,
                "Pharmacy": row.Pharmacy ? parseFloat(parseFloat(row.Pharmacy).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Lab": row.Lab ? parseFloat(parseFloat(row.Lab).toFixed()) : 0,
                "Radiology": row.Radiology ? parseFloat(parseFloat(row.Radiology).toFixed()) : 0,
                "Other Deduction": row.IPotherDeduction ? parseFloat(parseFloat(row.IPotherDeduction).toFixed()) : 0,
                // "Epidural Charges": row.epiduralCharges,
                // "Safety Consumable": row.safetyConsumable,
                // "Net Bill (Bill Report)": row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0,
                // "Net Bill (Calculated)": row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0,
                // "Item Gross": row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0,
                // "Item Net": row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0,
                "Net Bill (Bill Report)": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Net Bill (Calculated)": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Item Gross": row.itemGross ? parseFloat(parseFloat(row.itemGross).toFixed()) : 0,
                "Item Net": row.itemNet ? parseFloat(parseFloat(row.itemNet).toFixed()) : 0,
                // "Payable/Non Payable": row.PNP == 1 ? 'Payable' : 'Non Payable',
                "Package Inclusion": row.packageInclusion,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count ? parseFloat(parseFloat(row.Count).toFixed()) : 0,
                "Amount": row.doctorAmount ? parseFloat(parseFloat(row.doctorAmount).toFixed()) : 0,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "IP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadSummaryIPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...IPPayoutReports]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "IPNo": row.ip,
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Age": row.age,
                "Gender": row.gender,
                "Admitting Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "Admit Group": row.admitGroup,
                "Admission Purpose": row.admissionPurpose,
                // "Admission Date": row.AdmissionDate,
                // "Discharge Date": row.DischargeDate,
                "LOS(InDays)": row.LOS,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Bed Category": row.bedCategory,
                "Bill Type": row.billType,
                "Package Name": row.packageName,
                "Package Amount": row.PackageAmount ? parseFloat(parseFloat(row.PackageAmount).toFixed()) : 0,
                "Category": row.insuranceCategory,
                "TPA": row.tpaName,
                "Insurance": row.insurance,
                "Corporate Name": row.organisation,
                "User": row.User,
                "Month": row.Month,
                "ipGroup": row.ipGroup,
                "Sub Category": row.ipSubGroup,
                "ERP LOS": row.erpLos,
                "Pharmacy": row.Pharmacy ? parseFloat(parseFloat(row.Pharmacy).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Lab": row.Lab ? parseFloat(parseFloat(row.Lab).toFixed()) : 0,
                "Radiology": row.Radiology ? parseFloat(parseFloat(row.Radiology).toFixed()) : 0,
                "Other Deduction": row.IPotherDeduction ? parseFloat(parseFloat(row.IPotherDeduction).toFixed()) : 0,
                // "Epidural Charges": row.epiduralCharges,
                // "Safety Consumable": row.safetyConsumable,
                // "Net Bill (Bill Report)": row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0,
                // "Net Bill (Calculated)": row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0,
                // "Item Gross": row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0,
                // "Item Net": row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0,
                "Net Bill (Bill Report)": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Net Bill (Calculated)": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Item Gross": row.itemGross ? parseFloat(parseFloat(row.itemGross).toFixed()) : 0,
                "Item Net": row.itemNet ? parseFloat(parseFloat(row.itemNet).toFixed()) : 0,
                // "Payable/Non Payable": row.PNP == 1 ? 'Payable' : 'Non Payable',
                "Package Inclusion": row.packageInclusion,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count ? parseFloat(parseFloat(row.Count).toFixed()) : 0,
                "Amount": row.doctorAmount ? parseFloat(parseFloat(row.doctorAmount).toFixed()) : 0,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "CH Remarks": row.GLCoe,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "IP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadAdjustmentSummaryIPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...IPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '')]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "IPNo": row.ip,
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Age": row.age,
                "Gender": row.gender,
                "Admitting Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "Admit Group": row.admitGroup,
                "Admission Purpose": row.admissionPurpose,
                // "Admission Date": row.AdmissionDate,
                // "Discharge Date": row.DischargeDate,
                "LOS(InDays)": row.LOS,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Bed Category": row.bedCategory,
                "Bill Type": row.billType,
                "Package Name": row.packageName,
                "Package Amount": row.PackageAmount ? parseFloat(parseFloat(row.PackageAmount).toFixed()) : 0,
                "Category": row.insuranceCategory,
                "TPA": row.tpaName,
                "Insurance": row.insurance,
                "Corporate Name": row.organisation,
                "User": row.User,
                "Month": row.Month,
                "ipGroup": row.ipGroup,
                "Sub Category": row.ipSubGroup,
                "ERP LOS": row.erpLos,
                "Pharmacy": row.Pharmacy ? parseFloat(parseFloat(row.Pharmacy).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Lab": row.Lab ? parseFloat(parseFloat(row.Lab).toFixed()) : 0,
                "Radiology": row.Radiology ? parseFloat(parseFloat(row.Radiology).toFixed()) : 0,
                "Other Deduction": row.IPotherDeduction ? parseFloat(parseFloat(row.IPotherDeduction).toFixed()) : 0,
                // "Epidural Charges": row.epiduralCharges,
                // "Safety Consumable": row.safetyConsumable,
                // "Net Bill (Bill Report)": row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0,
                // "Net Bill (Calculated)": row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0,
                // "Item Gross": row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0,
                // "Item Net": row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0,
                "Net Bill (Bill Report)": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Net Bill (Calculated)": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Item Gross": row.itemGross ? parseFloat(parseFloat(row.itemGross).toFixed()) : 0,
                "Item Net": row.itemNet ? parseFloat(parseFloat(row.itemNet).toFixed()) : 0,
                // "Payable/Non Payable": row.PNP == 1 ? 'Payable' : 'Non Payable',
                "Package Inclusion": row.packageInclusion,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count ? parseFloat(parseFloat(row.Count).toFixed()) : 0,
                "Amount": row.doctorAmount ? parseFloat(parseFloat(row.doctorAmount).toFixed()) : 0,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "CH Remarks": row.GLCoe,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Adjustments-IP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadOPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...OPCalculatedData]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Tax": row.Tax ? parseFloat(parseFloat(row.Tax).toFixed()) : 0,
                "Package Overrun": row.PackageOverrun ? parseFloat(parseFloat(row.PackageOverrun).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Discount Type": row.DiscountType,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Ordering Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "User": row.User,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "OP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadSummaryOPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...OPPayoutReports]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Tax": row.Tax ? parseFloat(parseFloat(row.Tax).toFixed()) : 0,
                "Package Overrun": row.PackageOverrun ? parseFloat(parseFloat(row.PackageOverrun).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Discount Type": row.DiscountType,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Ordering Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "User": row.User,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "CH Remarks": row.GLCoe,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "OP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }

    const downloadAdjustmentSummaryOPCalculatedDataExcel = async () => {
        let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
        let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
        var payoutData = [...OPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '')]
        const newData = payoutData.map(row => {
            // delete row.tableData
            let obj = {
                // "SlNo": row.SlNo,
                "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
                "Bill No": row.BillNo,
                "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                "Patient Name": row.PatientName,
                "Item Group": row.ItemGroupName,
                "Item Name": row.ItemName,
                "Gross": row.Gross ? parseFloat(parseFloat(row.Gross).toFixed()) : 0,
                "Tax": row.Tax ? parseFloat(parseFloat(row.Tax).toFixed()) : 0,
                "Package Overrun": row.PackageOverrun ? parseFloat(parseFloat(row.PackageOverrun).toFixed()) : 0,
                "Discount": row.Discount ? parseFloat(parseFloat(row.Discount).toFixed()) : 0,
                "Discount Type": row.DiscountType,
                "Net": row.Net ? parseFloat(parseFloat(row.Net).toFixed()) : 0,
                "Ordering Physician": row.OrderingPhysician,
                "Specialisation": row.Specialisation,
                "User": row.User,
                "Doctor Name": row.ConductedBy,
                "Doctor LT ID": row.CONDUCTED_BY_LOGIN_ID,
                "Nature": row.NatureofDoctors,
                "Commercial Term": row.commercialTerm,
                "Qty": row.Count,
                "Ratio": row.Ratio,
                "Dr Fee": row.doctorFee ? parseFloat(parseFloat(row.doctorFee).toFixed()) : 0,
                "DP Team Remarks": row.Concatenate,
                "CH Remarks": row.GLCoe,
                "Exception": row.vlookup,
            }
            return obj
        })
        // console.log(newData,"newData--------------------")
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "Adjustments-OP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
        return true;
    }
    return (
        <>
            <Sidebar />
            <CssBaseline />
            <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
                <Navbar />
                <TabContext value={mainTab}>
                    <AppBar
                        position="static"
                        style={{ marginTop: "10px", backgroundColor: "#810e36" }}
                    >
                        <TabList
                            onChange={handleChangeMaintab}
                            aria-label="simple tabs example"
                            variant="scrollable"
                        >
                            <Tab label="Payout Calculation" value="Payout Calculation" hidden={user.user.roles !== 7} />
                            <Tab label="Payout Reports" value="Payout Reports" />
                        </TabList>
                    </AppBar>
                    <TabPanel value="Payout Calculation">
                        <Paper
                            style={{ marginBottom: "10px", border: "1px solid #810e36" }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                    <FormControl variant="outlined" style={{ width: "100%" }}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={center}
                                            onChange={(e) => selectedCenter(e)}
                                            label=" Select Center"
                                            fullWidth
                                        >
                                            <MenuItem value="Select the Center">
                                                Select Center
                                            </MenuItem>
                                            {centerName.length > 0 && centerName.map((ele) => {
                                                return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                    <FormControl variant="outlined" style={{ width: "100%" }}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={payoutCycle}
                                            onChange={(e) => selecPayoutCycle(e)}
                                            label=" Select Payout Cycle"
                                            fullWidth
                                        >
                                            <MenuItem disabled value="Select the Center">
                                                Select Payout Cycle
                                            </MenuItem>
                                            {payoutCycles.length > 0 && payoutCycles.map((ele) => {
                                                return (<MenuItem value={ele.label}>{ele.value}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                    {" "}
                                    <Button
                                        variant="contained"
                                        onClick={() => LoadData()}
                                        style={{
                                            height: "100%",
                                            backgroundColor: "#810e36",
                                            color: "white",
                                        }}
                                        fullWidth
                                    >
                                        Load Data{" "}
                                    </Button>
                                </Grid>
                            </Grid>
                            <TabContext value={calculationTab}>
                                <TabList
                                    onChange={handleChangeCalculationTab}
                                    aria-label="simple tabs example"
                                    variant="scrollable"
                                >
                                    <Tab label="CalculationIPD" value="CalculationIPD" />
                                    <Tab label="CalculationOPD" value="CalculationOPD" />
                                </TabList>
                                <TabPanel value="CalculationIPD" >
                                    <MaterialTable
                                        columns={[
                                            { title: "MPI", field: "MPI", render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            {
                                                title: "IP No",
                                                field: "IPNo",
                                            },
                                            { title: "Bill No", field: "BillNo" },
                                            { title: "Bill Date", field: "BillDate" },
                                            { title: "Bill Time", field: "BillTime" },
                                            { title: "PatientName", field: "PatientName" },
                                            { title: "Age", field: "Age" },
                                            { title: "Gender", field: "Gender" },
                                            { title: "Admitting Physician", field: "AdmittingPhysician" },
                                            { title: "Specialisation", field: "Specialisation" },
                                            { title: "AdmitGroup", field: "AdmitGroup" },
                                            { title: "Admission Purpose", field: "AdmissionPurpose" },
                                            { title: "Admission Date", field: "AdmissionDate" },
                                            { title: "Admission Time", field: "AdmissionTime" },
                                            { title: "Discharge Date", field: "DischargeDate" },
                                            { title: "Discharge Time", field: "DischargeTime" },
                                            { title: "LOSInDays", field: "LOSInDays" },
                                            { title: "Ward", field: "Ward" },
                                            { title: "Room", field: "Room" },
                                            { title: "Bed", field: "Bed" },
                                            { title: "Bed Category", field: "BedCategory" },
                                            { title: "BillType", field: "BillType" },
                                            { title: "Pharmacy Total", field: "PharmacyTotal" },
                                            { title: "Total", field: "Total" },
                                            { title: "Pharmacy Return", field: "PharmacyReturn" },
                                            { title: "Gross", field: "Gross" },
                                            { title: "Luxury Tax", field: "LuxuryTax" },
                                            { title: "Other Tax", field: "OtherTax" },
                                            { title: "Total Tax", field: "TotalTax" },
                                            { title: "Rounding", field: "Rounding" },
                                            { title: "Net", field: "Net" },
                                            { title: "Advance Adjusted", field: "AdvanceAdjusted" },
                                            { title: "NetAfter Advance", field: "NetAfterAdvance" },
                                            { title: "Cash", field: "Cash" },
                                            { title: "Card", field: "Card" },
                                            { title: "CHQ", field: "CHQ" },
                                            { title: "Amount Due", field: "AmountDue" },
                                            { title: "Package Name", field: "PackageName" },
                                            { title: "Package Amount", field: "PackageAmount" },
                                            { title: "Category", field: "Category" },
                                            { title: "TPA", field: "TPA" },
                                            { title: "Insurance", field: "Insurance" },
                                            { title: "Corporate Name", field: "CorporateName" },
                                            { title: "Auth Code", field: "AuthCode" },
                                            { title: "Approved Amount", field: "ApprovedAmount" },
                                            { title: "User", field: "User" },
                                            { title: "Discount", field: "Discount" },
                                            {
                                                title: "Radiology",
                                                field: "Radiology",
                                            },
                                            { title: "Lab", field: "Lab" },
                                            {
                                                title: "Pharmacy",
                                                field: "Pharmacy",
                                            },
                                            { title: "Other Deduction", field: "otherDeduction" },
                                            {
                                                title: "ipGroup", field: "ipGroup",
                                                lookup: {
                                                    "Gynec": "Gynec",
                                                    "Delivery": "Delivery",
                                                    "Pead": "Pead",
                                                    "Fertility": "Fertility",
                                                },
                                            },
                                            { title: "ipSubGroup", field: "ipSubGroup" },
                                            {
                                                title: "erpLos", field: "erpLos",
                                                lookup: {
                                                    "Gynec": "Gynec",
                                                    "Delivery": "Delivery",
                                                    "Pead": "Pead",
                                                    "Fertility": "Fertility",
                                                },
                                            },
                                            { title: "oTSurgeonCharges", field: "oTSurgeonCharges" },
                                            { title: "epiduralCharges", field: "epiduralCharges" },
                                            { title: "babyReceivingFee", field: "babyReceivingFee" },
                                            { title: "safetyConsumable", field: "safetyConsumable" },
                                            { title: "Referal Fee", field: "referalFee" },
                                            { title: "Total Deduction", field: "totalDeduction" },
                                            // { title: "PPE KIT", field: "ppeKit" },
                                            { title: "Conducting Doctor", field: "conductingDoctor" },
                                            { title: "Conducting Nature", field: "conductingNature" },
                                            { title: "Conducting Term", field: "conductingTerm" },
                                            { title: "Amount", field: "amount", render: (row) => row.amount ? numberWithCommas(parseFloat(row.amount).toFixed()) : 0 },
                                            { title: "Ratio", field: "ratio" },
                                            { title: "Doctor Fee", field: "doctorFee", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                            {
                                                title: "Annesthesist Doctor Fee",
                                                field: "annesthesistDoctorFee",
                                            },
                                            { title: "Ip Visist Doctor Fee", field: "ipVisistDoctorFee" },
                                            { title: "Nicu Doctor Fee", field: "nicuDoctorFee" },
                                            { title: "Misc Doctor Fee", field: "miscellaneousDoctorFee" },
                                            { title: "Radiology Doctor Fee", field: "radiologyDoctorFee" },
                                        ]}
                                        data={IPBillData}
                                        actions={[
                                            {
                                                icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                tooltip: "Export to Excel",
                                                onClick: () => downloadIPDPayoutBillingDetails(),
                                                isFreeAction: true
                                            }
                                        ]}
                                        options={{
                                            fixedColumns: {
                                                top: 1,
                                                right: 0
                                            },
                                            maxBodyHeight: '500px',
                                            filtering: true,
                                            sorting: true,
                                            // exportButton: true,
                                            exportAllData: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        icons={{
                                            Filter: "filter",
                                        }}
                                        onSelectionChange={(rows) => {
                                            setRowSelection(true);
                                            setChangeRowValue(rows);
                                        }}
                                        title="Ip-Payout Billing Details"
                                    />
                                    {IPBillData && IPBillData.length > 0 ?
                                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={initiateIPCalculation}
                                            >
                                                Initiate Calculation
                                            </Button>
                                        </div>
                                        :
                                        ""
                                    }
                                    {IPCalculatedData && IPCalculatedData.length > 0 ?
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: "Split",
                                                    field: "Action",
                                                    editable: 'never',
                                                    filtering: false,
                                                    hidden: (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && ((payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles))) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state)))) ? false : true,
                                                    render: (row) => (
                                                        <Tooltip title="Split Record">
                                                            <IconButton>
                                                                <CallSplitIcon
                                                                    style={{
                                                                        transform: "rotate(90deg)",
                                                                        color: "#810e36",
                                                                    }}
                                                                    onClick={() => {
                                                                        setSplitDialog(true);
                                                                        onSplitRow(row);
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ),
                                                },
                                                // { title: "SlNo", field: "SlNo", editable: 'never' },
                                                { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                { title: "IPNo", field: "ip", editable: 'never' },
                                                { title: "Bill No", field: "BillNo", editable: 'never' },
                                                { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                { title: "Age", field: "age", editable: 'never' },
                                                { title: "Gender", field: "gender", editable: 'never' },
                                                { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                                { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                { title: "Admit Group", field: "admitGroup", editable: 'never' },
                                                { title: "Admission Purpose", field: "admissionPurpose", editable: 'never' },
                                                // { title: "Admission Date", field: "AdmissionDate", editable: 'never' },
                                                // { title: "Discharge Date", field: "DischargeDate", editable: 'never' },
                                                { title: "LOS(InDays)", field: "LOS", editable: 'never' },
                                                {
                                                    title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                        "IP Physician Consulting": "IP Physician Consulting",
                                                        "OT Surgeon Charges": "OT Surgeon Charges",
                                                        "Radiology": "Radiology",
                                                        "Non OT Procedures": "Non OT Procedures",
                                                        "Anesthesia Services": "Anesthesia Services",
                                                        "Other Charges": "Other Charges",
                                                        "Pharmacy": "Pharmacy",
                                                        "Lab": "Lab",
                                                        "Registration Charge": "Registration Charge",
                                                        "Care Team": "Care Team",
                                                        "Procedure Charge": "Procedure Charge",
                                                        "Bed Charges": "Bed Charges",
                                                    }
                                                },
                                                { title: "Item Name", field: "ItemName", editable: 'never' },
                                                { title: "Bed Category", field: "bedCategory", editable: 'never' },
                                                { title: "Bill Type", field: "billType", editable: 'never' },
                                                { title: "Package Name", field: "packageName", editable: 'never' },
                                                { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                                                { title: "Category", field: "Category", editable: 'never' },
                                                { title: "TPA", field: "tpaName", editable: 'never' },
                                                { title: "Insurance", field: "insurance", editable: 'never' },
                                                { title: "Corporate Name", field: "organisation", editable: 'never' },
                                                { title: "User", field: "User", editable: 'never' },
                                                // { title: "Month", field: "month", editable: 'never' },
                                                { title: "Category", field: "ipGroup", editable: 'never' },
                                                { title: "Sub Category", field: "ipSubGroup", editable: 'never' },
                                                // { title: "Room Category", field: "", editable: 'never' },
                                                {
                                                    title: "ERP LOS", field: "erpLos", editable: 'never',
                                                    lookup: {
                                                        "Gynec": "Gynec",
                                                        "Delivery": "Delivery",
                                                        "Pead": "Pead",
                                                        "Fertility": "Fertility",
                                                    },
                                                },
                                                // { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                                                { title: "Pharmacy", field: "PharmacyTotal", editable: 'never', render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0 },
                                                { title: "Discount", field: "Discount", editable: 'never' },
                                                { title: "Lab", field: "Lab", editable: 'never', render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0 },
                                                { title: "Radiology", field: "Radiology", editable: 'never', render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0 },
                                                { title: "Other Deduction", field: "IPotherDeduction", editable: 'never', render: (row) => row.IPotherDeduction ? numberWithCommas(parseFloat(row.IPotherDeduction).toFixed()) : 0 },
                                                { title: "Epidural Charges", field: "epiduralCharges", editable: 'never', render: (row) => row.epiduralCharges ? numberWithCommas(parseFloat(row.epiduralCharges).toFixed()) : 0 },
                                                { title: "Safety Consumable", field: "safetyConsumable", editable: 'never', render: (row) => row.safetyConsumable ? numberWithCommas(parseFloat(row.safetyConsumable).toFixed()) : 0 },
                                                { title: "Net Bill (Bill Report)", field: "Gross", editable: 'never', render: (row) => row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0 },
                                                { title: "Net Bill (Calculated)", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                                { title: "Item Gross", field: "itemGross", editable: 'never', render: (row) => row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0 },
                                                { title: "Item Net", field: "itemNet", editable: 'never', render: (row) => row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0 },
                                                // { title: "Payable/Non Payable", field: "PNP", editable: 'never', render: (row) => row.PNP == 1 ? 'Payable' : 'Non Payable' },

                                                { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                                { title: "Doctor LTID", field: "CONDUCTED_BY_LOGIN_ID", validate: rowData => rowData.CONDUCTED_BY_LOGIN_ID !== '' && rowData.CONDUCTED_BY_LOGIN_ID !== null ? "" : "Doctor LTID cannot be empty" },
                                                {
                                                    title: "Nature", field: "NatureofDoctors",
                                                    lookup: {
                                                        "Surgeon Fee": "Surgeon Fee",
                                                        "IP Visit Fee": "IP Visit Fee",
                                                        "NICU": "NICU",
                                                        "Radiology": "Radiology",
                                                        "Non OT Procedures": "Non OT Procedures",
                                                        "Anesthesia Services": "Anesthesia Services",
                                                        "Other Charges": "Other Charges",
                                                        "Pharmacy": "Pharmacy",
                                                        "Lab": "Lab",
                                                        "Registration Charge": "Registration Charge",
                                                        "Care Team": "Care Team",
                                                        "Procedure Charge": "Procedure Charge",
                                                        "Bed Charges": "Bed Charges",
                                                    },
                                                    editable: 'never',
                                                },
                                                { title: "Terms", field: "commercialTerm", editable: 'never', },
                                                { title: "Qty", field: "Count", editable: 'never', },
                                                { title: "Amount", field: "doctorAmount", editable: (_, rowData) => rowData && rowData.commercialTerm != '', validate: rowData => rowData.doctorAmount == '' || rowData.doctorAmount == null || parseFloat(rowData.doctorAmount) <= 0 ? "Amount cannot be empty or less than 0" : rowData.commercialTerm.includes('Net Bill Amount') && parseFloat(rowData.doctorAmount) > parseFloat(rowData.Net) ? "Amount cannot be greater than Net Bill Amount" : rowData.commercialTerm.includes('Gross Bill Amount') && parseFloat(rowData.doctorAmount) > parseFloat(rowData.Gross) ? "Amount cannot be greater than Net Bill Amount" : rowData.commercialTerm.includes('Net Amount') && parseFloat(rowData.doctorAmount) > parseFloat(rowData.itemNet) ? "Amount cannot be greater than Net Item Amount" : rowData.commercialTerm.includes('Gross Amount') && parseFloat(rowData.doctorAmount) > parseFloat(rowData.itemGross) ? "Amount cannot be greater than Gross Item Amount" : rowData.commercialTerm.includes('Package Amount') && parseFloat(rowData.doctorAmount) > parseFloat(rowData.PackageAmount) ? "Amount cannot be greater than Package Amount" : "", },
                                                // { title: "DoctorFeeinChargePerunit", field: "" },
                                                { title: "Ratio", field: "Ratio", editable: 'never', },
                                                { title: "Dr Fee ", field: "doctorFee", type: 'numeric', editable: (_, rowData) => rowData && rowData.commercialTerm == '', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                                { title: "DP Team Remarks ", field: "Concatenate", validate: rowData => rowData.Concatenate !== '' && rowData.Concatenate !== null ? "" : "Remarks cannot be empty" },
                                                { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                            ]}
                                            data={IPCalculatedData}
                                            actions={[
                                                {
                                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadIPCalculatedDataExcel(),
                                                    isFreeAction: true
                                                }
                                            ]}
                                            editable={
                                                (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && ((payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles))) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state)))) ? {
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                const dataUpdate = [...IPCalculatedData];
                                                                const index = oldData.tableData.id;
                                                                dataUpdate[index] = newData;
                                                                if (newData.commercialTerm && newData.commercialTerm.includes('%')) {
                                                                    dataUpdate[index].doctorFee = parseFloat(parseFloat(newData.doctorAmount) * parseFloat(newData.Ratio)).toFixed()
                                                                }
                                                                console.log(oldData, newData, 'exception item update')
                                                                if (newData.Concatenate == '') {
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        title: "Alert",
                                                                        subtitle: "Please Add Remarks",
                                                                    });
                                                                    reject()
                                                                    return;
                                                                } else {
                                                                    updateBillItemData(newData, oldData, 'conditionally')
                                                                    setIPCalculatedData([...dataUpdate]);
                                                                    resolve();
                                                                }

                                                            }, 1000)
                                                        }),
                                                } : {}}
                                            options={{
                                                sorting: true,
                                                fixedColumns: {
                                                    top: 1,
                                                    right: 0
                                                },
                                                maxBodyHeight: '500px',
                                                filtering: true,
                                                exportButton: false,
                                                // exportAllData: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                            }}
                                            icons={{
                                                Filter: "filter",
                                            }}
                                            title="IP-Payout"
                                        />
                                        :
                                        ""
                                    }
                                </TabPanel>
                                <TabPanel value="CalculationOPD" >
                                    <MaterialTable
                                        columns={[
                                            { title: "MPI", field: "MPI", render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            { title: "Patient Name", field: "PatientName" },
                                            { title: "Bill Time", field: "BillTime" },
                                            {
                                                title: "Bill No",
                                                field: "BillNo",
                                                render: (row) => (
                                                    <div
                                                        style={{ cursor: "pointer", color: "red" }}
                                                        onClick={() => alert(row.BillNo)}
                                                    >
                                                        {row.BillNo}
                                                    </div>
                                                ),
                                            },
                                            { title: "Bill Date", field: "BillDate" },
                                            { title: "Admin Fee", field: "AdminFee" },
                                            { title: "Item Group Name", field: "ItemGroupName" },
                                            { title: "Item Name", field: "ItemName" },
                                            { title: "Gross", field: "Gross" },
                                            { title: "Tax", field: "Tax" },
                                            { title: "Discount", field: "Discount" },
                                            { title: "Net", field: "Net" },
                                            {
                                                title: "Share",
                                                field: "Share",
                                                render: (row) =>
                                                    row.Share == null ? <div>NA</div> : <div>{row.Share}</div>,
                                            },
                                            {
                                                title: "Ordering Physician",
                                                field: "OrderingPhysician",
                                            },
                                            { title: "Specialisation", field: "Specialisation" },
                                            {
                                                title: "ConductedBy",
                                                field: "ConductedBy",
                                                render: (row) =>
                                                    row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                            },
                                            { title: "User", field: "User" },
                                            { title: "Location", field: "Center" },
                                        ]}
                                        data={OPBillData}
                                        options={{
                                            sorting: true,
                                            exportButton: false,
                                            exportAllData: true,
                                            fixedColumns: {
                                                top: 1,
                                                right: 0
                                            },
                                            maxBodyHeight: '500px',
                                            filtering: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        actions={[
                                            {
                                                icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                tooltip: "Export to Excel",
                                                onClick: () => downloadOPDPayoutBillingDetails(),
                                                isFreeAction: true
                                            }
                                        ]}
                                        icons={{
                                            Filter: "filter",
                                        }}
                                        onSelectionChange={(rows) => {
                                            setRowSelection(true);
                                            setChangeRowValue(rows);
                                        }}
                                        title="OP-Payout Billing Details"
                                    />
                                    {OPBillData && OPBillData.length > 0 ?
                                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={initiateOPCalculation}
                                            >
                                                Initiate Calculation
                                            </Button>
                                        </div>
                                        :
                                        ""
                                    }
                                    {OPCalculatedData && OPCalculatedData.length > 0 ?
                                        <MaterialTable
                                            columns={[{
                                                title: "Split",
                                                field: "Action",
                                                editable: 'never',
                                                filtering: false,
                                                hidden: (payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                                render: (row) => (
                                                    <Tooltip title="Split Record">
                                                        <IconButton>
                                                            <CallSplitIcon
                                                                style={{
                                                                    transform: "rotate(90deg)",
                                                                    color: "#810e36",
                                                                }}
                                                                onClick={() => {
                                                                    setSplitDialog(true);
                                                                    onSplitRow(row);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                ),
                                            },
                                            // { title: "SlNo", field: "SlNo" },
                                            { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            {
                                                title: "Bill No",
                                                editable: 'never',
                                                field: "BillNo",
                                                render: (row) => (
                                                    <div
                                                        style={{ cursor: "pointer", color: "red" }}
                                                        onClick={() => alert(row.BillNo)}
                                                    >
                                                        {row.BillNo}
                                                    </div>
                                                ),
                                            },
                                            { title: "Bill Date", field: "BillDate", editable: 'never' },
                                            {
                                                title: "Bill Time", editable: 'never',
                                                field: "BillTime",
                                                render: (row) =>
                                                    <div>{row.BillTime == null ? moment(row.BillTime).format("LT") : row.BillTime}</div>,
                                            },
                                            // { title: "Bill Time", field: "BillTime" },
                                            { title: "Patient Name", field: "PatientName", editable: 'never' },
                                            {
                                                title: "Item Group Name", field: "ItemGroupName", editable: 'never', lookup: {
                                                    "OP Physician Consulting": "OP Physician Consulting",
                                                    "Radiology": "Radiology",
                                                    "Non OT Procedures": "Non OT Procedures",
                                                    "Other Charges": "Other Charges",
                                                    "Pharmacy": "Pharmacy",
                                                    "Lab": "Lab",
                                                    "Registration Charge": "Registration Charge",
                                                    "Medical Equipments": "Medical Equipments",
                                                    "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                    "Ear Piercing - Product": "Ear Piercing - Product",
                                                    "Ear Piercing - Service": "Ear Piercing - Service",
                                                }
                                            },
                                            { title: "Item Name", field: "ItemName", editable: 'never' },
                                            { title: "Gross", field: "Gross", editable: 'never' },
                                            { title: "Tax", field: "Tax", editable: 'never' },
                                            { title: "Package Overrun", field: "PackageOverrun", editable: 'never' },
                                            { title: "Discount", field: "Discount", editable: 'never' },
                                            { title: "Discount Type", field: "DiscountType", editable: 'never' },
                                            {
                                                title: "Admin Fee",
                                                field: "AdminFee", editable: 'never',
                                                render: (row) =>
                                                    // row.AdminFee == null ? <div>NA</div> : <div>{row.AdminFee}</div>,
                                                    row.ItemName.includes('Consultation') ? row.consultation : row.ItemName.includes('Follow') ? row.followup : 0,
                                            },
                                            {
                                                title: "Type",
                                                field: "doctorType", editable: 'never',
                                            },
                                            {
                                                title: "OP/IPD",
                                                field: "OPIPD", editable: 'never',
                                                render: (row) =>
                                                    row.OPIPD == null ? <div>OPD</div> : <div>{row.OPIPD}</div>,
                                            },
                                            // { title: "Comment", field: "comment" },
                                            {
                                                title: "Ordering Physician",
                                                field: "OrderingPhysician", editable: 'never',
                                            },
                                            { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                            { title: "User", field: "User", editable: 'never' },
                                            { title: "Net", field: "Net", editable: 'never' },
                                            {
                                                title: "Conducted By",
                                                field: "ConductedBy",
                                                render: (row) =>
                                                    row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                            },
                                            {
                                                title: "Conducted By Login Id",
                                                field: "CONDUCTED_BY_LOGIN_ID",
                                                render: (row) =>
                                                    row.CONDUCTED_BY_LOGIN_ID == null ? <div>NA</div> : <div>{row.CONDUCTED_BY_LOGIN_ID}</div>,
                                            },
                                            {
                                                title: "Doctor Payout",
                                                field: "doctorFee",
                                                render: (row) =>
                                                    row.doctorFee != null ? (
                                                        <div style={{ cursor: "pointer", color: "green" }}>
                                                            {row.doctorFee.toFixed(2)}
                                                        </div>
                                                    ) : (
                                                        <div style={{ cursor: "pointer", color: "red" }}>
                                                            {row.doctorFee}
                                                        </div>
                                                    ),
                                                type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0
                                            },
                                            { title: "DP Team Remarks ", field: "Concatenate", validate: rowData => rowData.Concatenate !== '' && rowData.Concatenate !== null ? "" : "Remarks cannot be empty" },
                                            {
                                                title: "Share",
                                                field: "Ratio",
                                                type: 'numeric',
                                                editable: 'never',
                                                render: (row) =>
                                                    row.Ratio != null ? (
                                                        <div >
                                                            {row.Ratio}
                                                        </div>
                                                    ) : (
                                                        <div >
                                                            NA
                                                        </div>
                                                    ),
                                                validate: rowData => parseFloat(rowData.Ratio) < 0 ? "Share cannot be less than 0" : "", render: (row) => row.Ratio ? numberWithCommas(parseFloat(row.Ratio).toFixed()) : 0
                                            },
                                            {
                                                title: "Fixed Amount",
                                                field: "doctorAmount",
                                                editable: 'never',
                                                type: 'numeric',
                                                render: (row) =>
                                                    row.doctorAmount != null ? (
                                                        <div >
                                                            {row.doctorAmount}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            0
                                                        </div>
                                                    ),
                                                validate: rowData => parseFloat(rowData.doctorAmount) < 0 ? "Fixed Amount cannot be less than 0" : "", render: (row) => row.doctorAmount ? numberWithCommas(parseFloat(row.doctorAmount).toFixed()) : 0
                                            },
                                            // {
                                            //   title: "Payable",
                                            //   field: "PayableNonPayable",
                                            //   lookup: {
                                            //     1: "Yes",
                                            //     0: "No",
                                            //   },
                                            //   editable: 'never',
                                            // },
                                            // {
                                            //   title: "Conducted By Final",
                                            //   field: "ConductedByFinal",
                                            // },
                                            // { title: "OP/IPD", field: "OPIPD" },
                                            { title: "Center", field: "Location", editable: 'never' },
                                            { title: "Exception Issue", field: "vlookup", editable: 'never' },
                                                // { title: "Month", field: "Month", editable: 'never' },
                                                // { title: "Year", field: "Period", editable: 'never' },
                                            ]}
                                            actions={[
                                                {
                                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadOPCalculatedDataExcel(),
                                                    isFreeAction: true
                                                }
                                            ]}
                                            data={OPCalculatedData}
                                            editable={(payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state))) ? {
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            const dataUpdate = [...OPCalculatedData];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            console.log(oldData, newData, 'exception item update')
                                                            if (newData.Concatenate == '') {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: "Alert",
                                                                    subtitle: "Please Add Remarks",
                                                                });
                                                                reject()
                                                                return;
                                                            } else {
                                                                updateBillItemData(newData, oldData)
                                                                setOPCalculatedData([...dataUpdate]);
                                                                resolve();
                                                            }

                                                        }, 1000)
                                                    }),
                                            } : {}}
                                            icons={{
                                                Filter: "filter",
                                            }}
                                            options={{
                                                sorting: true,
                                                fixedColumns: {
                                                    top: 1,
                                                    right: 0
                                                },
                                                maxBodyHeight: '500px',
                                                filtering: true,
                                                exportButton: false,
                                                rowStyle: (rowData) => ({
                                                    backgroundColor: rowData.status === "Yes" ? "#ad4079" : "#FFF",
                                                }),
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                                exportAllData: true,
                                            }}
                                            onSelectionChange={(rows) => {
                                                setRowSelection(true);
                                                setChangeRowValue(rows);
                                            }}
                                            title="OP-Payout Calculation Details"
                                        />
                                        :
                                        ""
                                    }
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </TabPanel>
                    <TabPanel value="Payout Reports" >
                        <Paper>
                            <MaterialTable
                                columns={[
                                    { title: "Start Date", field: "startDate" },
                                    { title: "End Date", field: "endDate" },
                                    { title: "Center", field: "center" },
                                    {
                                        title: "Status", field: "currentStatus", lookup: {
                                            "To be verified by BH": "To be verified by BH",
                                            "To be verified by DP Head": "To be verified by DP Head",
                                            "DP Head has send back to revise the payouts": "DP Head has send back to revise the payouts",
                                            "COO has send back to revise the payouts": "COO has send back to revise the payouts",
                                            "BH has send back to revise the payouts": "BH has send back to revise the payouts",
                                            "To be verified by COO": "To be verified by COO",
                                            "To be review by DP Team": "To be review by DP Team",
                                            "Sent to D365": "Sent to D365",
                                            "Payout initiated": "Payout initiated",
                                            "Pending": "Pending",
                                        }
                                    },
                                    {
                                        title: "Action", field: "Action", filtering: false, render: (row) =>
                                            <IconButton style={{ color: "#810e36" }} aria-label="close" onClick={() => getPayoutReports(row)}>
                                                <VisibilityIcon />
                                            </IconButton>
                                    },
                                ]}
                                data={payoutReq}
                                options={{
                                    sorting: true,
                                    fixedColumns: {
                                        top: 1,
                                        right: 0
                                    },
                                    maxBodyHeight: '500px',
                                    filtering: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    selection: true,
                                    selectionProps: rowData => ({
                                        disabled: rowData.name === 'Mehmet',
                                        color: 'primary'
                                    }),
                                }}
                                // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows')}
                                icons={{
                                    Filter: "filter",
                                }}
                                title="Payouts Request"
                            />
                            {
                                payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.RetainerPayoutDataStatus && (payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_approval.state || payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.init.state) && user.user.roles == 7 ?
                                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => sendToCH()}
                                        >
                                            Send to center head
                                        </Button>
                                    </div>
                                    :
                                    payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.roles == 2 ?
                                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => sendToDPReview()}
                                            >
                                                Send to DP Head
                                            </Button>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => sendBackToDP(payoutState.ch_rejected.state)}
                                            >
                                                Send back to Revise
                                            </Button>
                                        </div>
                                        :
                                        payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && user.user.roles == 12 ?
                                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => sendToCOO()}
                                                >
                                                    Send to COO
                                                </Button>
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => sendBackToCH(payoutState.dp_rejected.state)}
                                                >
                                                    Send back to Revise
                                                </Button>
                                            </div>
                                            :
                                            payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.rd_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.rd_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.rd_approval.state && user.user.roles == 1 ?
                                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        size="small"
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => sendToDP()}
                                                    >
                                                        Approved
                                                    </Button>
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        size="small"
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => sendBackToCH(payoutState.coo_rejected.state)}
                                                    >
                                                        Send back to Revise
                                                    </Button>
                                                </div>
                                                :
                                                payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && user.user.roles == 7 ?
                                                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                        <Button
                                                            style={{ marginLeft: "10px" }}
                                                            size="small"
                                                            color="secondary"
                                                            variant="contained"
                                                        // onClick={() => sendToDP()}
                                                        >
                                                            Send TO D365
                                                        </Button>
                                                    </div>
                                                    : ""
                            }
                            <TabContext value={payoutRequestCalculationTab}>
                                <TabList
                                    onChange={handleChangePayoutRequestCalculationTab}
                                    aria-label="simple tabs example"
                                    variant="scrollable"
                                >
                                    <Tab label="Retainer" value="Retainer" disabled={user.user.roles == 1} />
                                    <Tab label="MG" value="MG" disabled={user.user.roles == 1} />
                                    <Tab label="IPD" value="IPD" disabled={user.user.roles == 1} />
                                    <Tab label="OPD" value="OPD" disabled={user.user.roles == 1} />
                                    <Tab label="Consolidated" value="Consolidated" />
                                    <Tab label="Consolidated Adjustments" value="Consolidated Adjustments" />
                                </TabList>
                                <TabPanel value="IPD" >
                                    {IPPayoutReports && IPPayoutReports.length > 0 ?
                                        <MaterialTable
                                            columns={[
                                                {
                                                    title: "Split",
                                                    field: "Action",
                                                    editable: 'never',
                                                    filtering: false,
                                                    hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                                    render: (row) => (
                                                        <Tooltip title="Split Record">
                                                            <IconButton>
                                                                <CallSplitIcon
                                                                    style={{
                                                                        transform: "rotate(90deg)",
                                                                        color: "#810e36",
                                                                    }}
                                                                    onClick={() => {
                                                                        setSplitDialog(true);
                                                                        onSplitRow(row);
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ),
                                                },
                                                // { title: "SlNo", field: "SlNo", editable: 'never' },
                                                { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                { title: "IPNo", field: "ip", editable: 'never' },
                                                { title: "Bill No", field: "BillNo", editable: 'never' },
                                                { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                { title: "Age", field: "age", editable: 'never' },
                                                { title: "Gender", field: "gender", editable: 'never' },
                                                { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                                { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                { title: "Admit Group", field: "admitGroup", editable: 'never' },
                                                { title: "Admission Purpose", field: "admissionPurpose", editable: 'never' },
                                                // { title: "Admission Date", field: "AdmissionDate", editable: 'never' },
                                                // { title: "Discharge Date", field: "DischargeDate", editable: 'never' },
                                                { title: "LOS(InDays)", field: "LOS", editable: 'never' },
                                                {
                                                    title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                        "IP Physician Consulting": "IP Physician Consulting",
                                                        "OT Surgeon Charges": "OT Surgeon Charges",
                                                        "Radiology": "Radiology",
                                                        "Non OT Procedures": "Non OT Procedures",
                                                        "Anesthesia Services": "Anesthesia Services",
                                                        "Other Charges": "Other Charges",
                                                        "Pharmacy": "Pharmacy",
                                                        "Lab": "Lab",
                                                        "Registration Charge": "Registration Charge",
                                                        "Care Team": "Care Team",
                                                        "Procedure Charge": "Procedure Charge",
                                                        "Bed Charges": "Bed Charges",
                                                    }
                                                },
                                                { title: "Item Name", field: "ItemName", editable: 'never' },
                                                { title: "Bed Category", field: "bedCategory", editable: 'never' },
                                                { title: "Bill Type", field: "billType", editable: 'never' },
                                                { title: "Package Name", field: "packageName", editable: 'never' },
                                                { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                                                { title: "Category", field: "Category", editable: 'never' },
                                                { title: "TPA", field: "tpaName", editable: 'never' },
                                                { title: "Insurance", field: "insurance", editable: 'never' },
                                                { title: "Corporate Name", field: "organisation", editable: 'never' },
                                                { title: "User", field: "User", editable: 'never' },
                                                // { title: "Month", field: "month", editable: 'never' },
                                                { title: "Category", field: "ipGroup", editable: 'never' },
                                                { title: "Sub Category", field: "ipSubGroup", editable: 'never' },
                                                // { title: "Room Category", field: "", editable: 'never' },
                                                {
                                                    title: "ERP LOS", field: "erpLos", editable: 'never',
                                                    lookup: {
                                                        "Gynec": "Gynec",
                                                        "Delivery": "Delivery",
                                                        "Pead": "Pead",
                                                        "Fertility": "Fertility",
                                                    },
                                                },
                                                // { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                                                { title: "Pharmacy", field: "PharmacyTotal", editable: 'never', render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0 },
                                                { title: "Discount", field: "Discount", editable: 'never' },
                                                { title: "Lab", field: "Lab", editable: 'never', render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0 },
                                                { title: "Radiology", field: "Radiology", editable: 'never', render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0 },
                                                { title: "Other Deduction", field: "otherDeduction", editable: 'never', render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0 },
                                                { title: "Epidural Charges", field: "epiduralCharges", editable: 'never', render: (row) => row.epiduralCharges ? numberWithCommas(parseFloat(row.epiduralCharges).toFixed()) : 0 },
                                                { title: "Safety Consumable", field: "safetyConsumable", editable: 'never', render: (row) => row.safetyConsumable ? numberWithCommas(parseFloat(row.safetyConsumable).toFixed()) : 0 },
                                                { title: "Net Bill (Bill Report)", field: "Gross", editable: 'never', render: (row) => row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0 },
                                                { title: "Net Bill (Calculated)", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                                { title: "Item Gross", field: "itemGross", editable: 'never', render: (row) => row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0 },
                                                { title: "Item Net", field: "itemNet", editable: 'never', render: (row) => row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0 },
                                                // { title: "Payable/Non Payable", field: "PNP", editable: 'never', render: (row) => row.PNP == 1 ? 'Payable' : 'Non Payable' },

                                                { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                                { title: "Doctor LTID", field: "CONDUCTED_BY_LOGIN_ID", validate: rowData => rowData.CONDUCTED_BY_LOGIN_ID !== '' && rowData.CONDUCTED_BY_LOGIN_ID !== null ? "" : "Doctor LTID cannot be empty" },
                                                {
                                                    title: "Nature", field: "NatureofDoctors",
                                                    lookup: {
                                                        "Surgeon Fee": "Surgeon Fee",
                                                        "IP Visit Fee": "IP Visit Fee",
                                                        "NICU": "NICU",
                                                        "Radiology": "Radiology",
                                                        "Non OT Procedures": "Non OT Procedures",
                                                        "Anesthesia Services": "Anesthesia Services",
                                                        "Other Charges": "Other Charges",
                                                        "Pharmacy": "Pharmacy",
                                                        "Lab": "Lab",
                                                        "Registration Charge": "Registration Charge",
                                                        "Care Team": "Care Team",
                                                        "Procedure Charge": "Procedure Charge",
                                                        "Bed Charges": "Bed Charges",
                                                    },
                                                },
                                                { title: "Terms", field: "commercialTerm" },
                                                { title: "Qty", field: "Count" },
                                                { title: "Amount", field: "doctorAmount" },
                                                // { title: "DoctorFeeinChargePerunit", field: "" },
                                                { title: "Ratio", field: "Ratio" },
                                                { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                                { title: "DP Team Remarks ", field: "Concatenate", editable: 'never' },
                                                { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                                { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                            ]}
                                            data={IPPayoutReports}
                                            actions={[
                                                {
                                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadSummaryIPCalculatedDataExcel(),
                                                    isFreeAction: true
                                                }
                                            ]}
                                            editable={
                                                (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles)) ? {
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                const dataUpdate = [...IPPayoutReports];
                                                                const index = oldData.tableData.id;
                                                                dataUpdate[index] = newData;
                                                                console.log(oldData, newData, 'exception item update')
                                                                if (newData.GLCoe == '') {
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        title: "Alert",
                                                                        subtitle: "Please Add Remarks",
                                                                    });
                                                                    reject()
                                                                    return;
                                                                } else {
                                                                    updateBillItemData(newData, oldData)
                                                                    setIPCalculatedData([...dataUpdate]);
                                                                    resolve();
                                                                }

                                                            }, 1000)
                                                        }),
                                                } : {}}
                                            options={{
                                                sorting: true,
                                                fixedColumns: {
                                                    top: 1,
                                                    right: 0
                                                },
                                                maxBodyHeight: '500px',
                                                filtering: true,
                                                exportButton: false,
                                                // exportAllData: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                            }}
                                            icons={{
                                                Filter: "filter",
                                            }}
                                            title="IP-Payout"
                                        />
                                        :
                                        "No Data Found"
                                    }
                                </TabPanel>
                                <TabPanel value="OPD" >
                                    {OPPayoutReports && OPPayoutReports.length > 0 ?
                                        <MaterialTable
                                            columns={[{
                                                title: "Split",
                                                field: "Action",
                                                editable: 'never',
                                                filtering: false,
                                                hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                                render: (row) => (
                                                    <Tooltip title="Split Record">
                                                        <IconButton>
                                                            <CallSplitIcon
                                                                style={{
                                                                    transform: "rotate(90deg)",
                                                                    color: "#810e36",
                                                                }}
                                                                onClick={() => {
                                                                    setSplitDialog(true);
                                                                    onSplitRow(row);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                ),
                                            },
                                            // { title: "SlNo", field: "SlNo" },
                                            { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            {
                                                title: "Bill No",
                                                editable: 'never',
                                                field: "BillNo",
                                                render: (row) => (
                                                    <div
                                                        style={{ cursor: "pointer", color: "red" }}
                                                        onClick={() => alert(row.BillNo)}
                                                    >
                                                        {row.BillNo}
                                                    </div>
                                                ),
                                            },
                                            { title: "Bill Date", field: "BillDate", editable: 'never' },
                                            {
                                                title: "Bill Time", editable: 'never',
                                                field: "BillTime",
                                                render: (row) =>
                                                    <div>{row.BillTime == null ? moment(row.BillTime).format("LT") : row.BillTime}</div>,
                                            },
                                            // { title: "Bill Time", field: "BillTime" },
                                            { title: "Patient Name", field: "PatientName", editable: 'never' },
                                            {
                                                title: "Item Group Name", field: "ItemGroupName", editable: 'never', lookup: {
                                                    "OP Physician Consulting": "OP Physician Consulting",
                                                    "Radiology": "Radiology",
                                                    "Non OT Procedures": "Non OT Procedures",
                                                    "Other Charges": "Other Charges",
                                                    "Pharmacy": "Pharmacy",
                                                    "Lab": "Lab",
                                                    "Registration Charge": "Registration Charge",
                                                    "Medical Equipments": "Medical Equipments",
                                                    "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                    "Ear Piercing - Product": "Ear Piercing - Product",
                                                    "Ear Piercing - Service": "Ear Piercing - Service",
                                                }
                                            },
                                            { title: "Item Name", field: "ItemName", editable: 'never' },
                                            { title: "Gross", field: "Gross", editable: 'never' },
                                            { title: "Tax", field: "Tax", editable: 'never' },
                                            { title: "Package Overrun", field: "PackageOverrun", editable: 'never' },
                                            { title: "Discount", field: "Discount", editable: 'never' },
                                            { title: "Discount Type", field: "DiscountType", editable: 'never' },
                                            {
                                                title: "Admin Fee",
                                                field: "AdminFee", editable: 'never',
                                                render: (row) =>
                                                    // row.AdminFee == null ? <div>NA</div> : <div>{row.AdminFee}</div>,
                                                    row.ItemName.includes('Consultation') ? row.consultation : row.ItemName.includes('Follow') ? row.followup : 0,
                                            },
                                            {
                                                title: "Type",
                                                field: "doctorType", editable: 'never',
                                            },
                                            {
                                                title: "OP/IPD",
                                                field: "OPIPD", editable: 'never',
                                                render: (row) =>
                                                    row.OPIPD == null ? <div>OPD</div> : <div>{row.OPIPD}</div>,
                                            },
                                            // { title: "Comment", field: "comment" },
                                            {
                                                title: "Ordering Physician",
                                                field: "OrderingPhysician", editable: 'never',
                                            },
                                            { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                            { title: "User", field: "User", editable: 'never' },
                                            { title: "Net", field: "Net", editable: 'never' },
                                            {
                                                title: "Conducted By",
                                                field: "ConductedBy",
                                                render: (row) =>
                                                    row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                            },
                                            {
                                                title: "Conducted By Login Id",
                                                field: "CONDUCTED_BY_LOGIN_ID",
                                                render: (row) =>
                                                    row.CONDUCTED_BY_LOGIN_ID == null ? <div>NA</div> : <div>{row.CONDUCTED_BY_LOGIN_ID}</div>,
                                            },
                                            {
                                                title: "Doctor Payout",
                                                field: "doctorFee",
                                                render: (row) =>
                                                    row.doctorFee != null ? (
                                                        <div style={{ cursor: "pointer", color: "green" }}>
                                                            {row.doctorFee.toFixed(2)}
                                                        </div>
                                                    ) : (
                                                        <div style={{ cursor: "pointer", color: "red" }}>
                                                            {row.doctorFee}
                                                        </div>
                                                    ),
                                                type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0
                                            },
                                            { title: "DP Team Remarks ", field: "Concatenate", editable: "never" },
                                            { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && user.user.roles != 7 && rowData.GLCoe !== null ? "" : "Remarks cannot be empty", },
                                            {
                                                title: "Share",
                                                field: "Ratio",
                                                type: 'numeric',
                                                render: (row) =>
                                                    row.Ratio != null ? (
                                                        <div >
                                                            {row.Ratio}
                                                        </div>
                                                    ) : (
                                                        <div >
                                                            NA
                                                        </div>
                                                    ),
                                                validate: rowData => parseFloat(rowData.Ratio) < 0 ? "Share cannot be less than 0" : "", render: (row) => row.Ratio ? numberWithCommas(parseFloat(row.Ratio).toFixed()) : 0
                                            },
                                            {
                                                title: "Fixed Amount",
                                                field: "doctorAmount",
                                                type: 'numeric',
                                                render: (row) =>
                                                    row.doctorAmount != null ? (
                                                        <div >
                                                            {row.doctorAmount}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            0
                                                        </div>
                                                    ),
                                                validate: rowData => parseFloat(rowData.doctorAmount) < 0 ? "Fixed Amount cannot be less than 0" : "", render: (row) => row.doctorAmount ? numberWithCommas(parseFloat(row.doctorAmount).toFixed()) : 0
                                            },
                                            // {
                                            //   title: "Payable",
                                            //   field: "PayableNonPayable",
                                            //   lookup: {
                                            //     1: "Yes",
                                            //     0: "No",
                                            //   },
                                            //   editable: 'never',
                                            // },
                                            // {
                                            //   title: "Conducted By Final",
                                            //   field: "ConductedByFinal",
                                            // },
                                            // { title: "OP/IPD", field: "OPIPD" },
                                            { title: "Center", field: "Location", editable: 'never' },
                                            { title: "Exception Issue", field: "vlookup", editable: 'never' },
                                                // { title: "Month", field: "Month", editable: 'never' },
                                                // { title: "Year", field: "Period", editable: 'never' },
                                            ]}
                                            actions={[
                                                {
                                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadSummaryOPCalculatedDataExcel(),
                                                    isFreeAction: true
                                                }
                                            ]}
                                            data={OPPayoutReports}
                                            editable={(payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles)) ? {
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            const dataUpdate = [...OPPayoutReports];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            console.log(oldData, newData, 'exception item update')
                                                            if ((user.user.roles == 7 && newData.Concatenate == '') || (user.user.roles != 7 && newData.GLCoe == '')) {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: "Alert",
                                                                    subtitle: "Please Add Remarks",
                                                                });
                                                                reject()
                                                                return;
                                                            } else {
                                                                updateBillItemData(newData, oldData)
                                                                setOPCalculatedData([...dataUpdate]);
                                                                resolve();
                                                            }

                                                        }, 1000)
                                                    }),
                                            } : {}}
                                            icons={{
                                                Filter: "filter",
                                            }}
                                            options={{
                                                sorting: true,
                                                fixedColumns: {
                                                    top: 1,
                                                    right: 0
                                                },
                                                maxBodyHeight: '500px',
                                                filtering: true,
                                                exportButton: false,
                                                rowStyle: (rowData) => ({
                                                    backgroundColor: rowData.status === "Yes" ? "#ad4079" : "#FFF",
                                                }),
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                                exportAllData: true,
                                            }}
                                            onSelectionChange={(rows) => {
                                                setRowSelection(true);
                                                setChangeRowValue(rows);
                                            }}
                                            title="OP-Payout Calculation Details"
                                        />
                                        :
                                        "No Data Found"
                                    }
                                </TabPanel>
                                <TabPanel value="MG" >
                                    {TempData && TempData.length > 0 ?
                                        <>
                                            <MaterialTable
                                                columns={[
                                                    { title: "D365 ID", field: "d365_id", editable: 'never', render: (row) => row.d365_id ? row.d365_id : '-' },
                                                    {
                                                        title: "Doctor Name",
                                                        field: "name",
                                                        render: (row) => row.name.includes('Dr. ') ? row.name : "Dr. " + row.name
                                                    },
                                                    { title: "Doctor LT ID", field: "email" },
                                                    { title: "PAN", field: "panno" },
                                                    { title: "Term", field: "mgTerm" },
                                                    { title: "Date of Joining", field: "doj" },
                                                    { title: "Previous Agreement Date", field: "doj", editable: 'never' },
                                                    { title: "Current Agreement Date", field: "doj", editable: 'never' },
                                                    {
                                                        title: "Doctor Type", field: "category",
                                                        lookup: {
                                                            "Full Time Consultant": "Full Time Consultant",
                                                            "Visiting Consultant": "Visiting Consultant",
                                                            "Retainer": "Retainer",
                                                            "Minimum Guarentee(MG)": "Minimum Guarentee(MG)",
                                                        }
                                                    },
                                                    { title: "MG / Month", field: "minguranteepermonth", render: (row) => (row.minguranteepermonth ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "Rs. 0") },
                                                    { title: "Payout", field: "Payout", render: (row) => (row.Payout ? "Rs. " + numberWithCommas(row.Payout) : "Rs. 0") },
                                                    {
                                                        title: "MG Earning", field: "Payout", render: (row) => {
                                                            if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                                                                return "Rs. " + numberWithCommas(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
                                                            } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                                                                return "Rs. -" + numberWithCommas((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
                                                            } else {
                                                                return "Rs. 0"
                                                            }
                                                        }
                                                    },
                                                ]}
                                                data={TempData.filter((item) => item.category === "Minimum Guarentee(MG)")}
                                                options={{
                                                    sorting: true,
                                                    // exportButton: true,
                                                    exportAllData: true,
                                                    filtering: true,
                                                    pageSize: 5,
                                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                    search: true,
                                                }}
                                                icons={{
                                                    Filter: "filter",
                                                }}
                                                actions={[
                                                    {
                                                        icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                        tooltip: "Export to Excel",
                                                        onClick: () => downloadMGExcel(),
                                                        isFreeAction: true
                                                    },
                                                ]}
                                                onSelectionChange={(rows) => {
                                                    setRowSelection(true);
                                                    setChangeRowValue(rows);
                                                }}
                                                title="MG Payout Report"
                                            />
                                        </>
                                        : ""}
                                </TabPanel>
                                <TabPanel value="Consolidated" >
                                    {TempData && TempData.length > 0 ?
                                        <>
                                            <MaterialTable
                                                columns={[
                                                    { title: "D365 ID", field: "d365_id", editable: 'never', render: (row) => row.d365_id ? row.d365_id : '-' },
                                                    {
                                                        title: "Doctor Name",
                                                        field: "name",
                                                        editable: 'never',
                                                        render: (row) => row.name.includes('Dr. ') ? row.name : "Dr. " + row.name
                                                    },
                                                    { title: "Doctor LT ID", field: "email", editable: 'never', },
                                                    { title: "PAN", field: "panno", editable: 'never', },
                                                    { title: "Date of Joining", field: "doj", editable: 'never', },
                                                    { title: "Previous Agreement Date", field: "doj", editable: 'never' },
                                                    { title: "Current Agreement Date", field: "doj", editable: 'never' },
                                                    {
                                                        title: "Doctor Type", field: "category",
                                                        editable: 'never',
                                                        lookup: {
                                                            "Full Time Consultant": "Full Time Consultant",
                                                            "Visiting Consultant": "Visiting Consultant",
                                                            "Retainer": "Retainer",
                                                            "Minimum Guarentee(MG)": "Minimum Guarentee(MG)",
                                                        }
                                                    },
                                                    { title: "MG / Month", field: "minguranteepermonth", editable: 'never', render: (row) => (row.minguranteepermonth ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "Rs. 0") },
                                                    { title: "Fixed", field: "fixedAmount", editable: 'never', render: (row) => (row.fixedAmount ? "Rs. " + numberWithCommas(row.fixedAmount) : "Rs. 0") },
                                                    { title: "OPD Payout", field: "OPPayout", editable: 'never', render: (row) => (row.OPPayout ? "Rs. " + numberWithCommas(row.OPPayout) : "Rs. 0") },
                                                    { title: "OPD Revenue", field: "OPDRevenue", editable: 'never', render: (row) => (row.OPDRevenue ? "Rs. " + numberWithCommas(row.OPDRevenue) : "Rs. 0") },
                                                    { title: "OPD Revenue (%)", field: "OPDRevenue", editable: 'never', render: (row) => (row.OPDRevenue ? ((Math.round((row.OPPayout / row.OPDRevenue) * 100))) + " %" : 0) },
                                                    { title: "IPD Payout", field: "IPPayout", editable: 'never', render: (row) => (row.IPPayout ? "Rs. " + numberWithCommas(row.IPPayout) : "Rs. 0") },
                                                    { title: "IPD Revenue", field: "IPDRevenue", editable: 'never', render: (row) => (row.IPDRevenue ? "Rs. " + numberWithCommas(row.IPDRevenue) : "Rs. 0") },
                                                    { title: "IPD Revenue (%)", field: "IPDRevenue", editable: 'never', render: (row) => (row.IPDRevenue ? ((Math.round((row.IPPayout / row.IPDRevenue) * 100))) + " %" : 0) },
                                                    { title: "Calculated Payout", field: "Payout", editable: 'never', render: (row) => (row.Payout ? "Rs. " + numberWithCommas(row.Payout) : "Rs. 0") },
                                                    { title: "Addition", field: "otherEarning", type: 'numeric', validate: rowData => parseFloat(rowData.otherEarning) < 0 ? "Addition cannot be less than 0" : "", },
                                                    { title: "Addition Remarks", field: "otherEarningRemarks", validate: rowData => parseFloat(rowData.otherEarning) > 0 && rowData.otherEarningRemarks == "" ? "Addition Remarks cannot be empty" : "", },
                                                    { title: "Deduction", field: "otherDeduction", type: 'numeric', validate: rowData => parseFloat(rowData.otherDeduction) < 0 ? "Deduction cannot be less than 0" : "", },
                                                    { title: "Deduction Remarks", field: "otherDeductionRemarks", validate: rowData => parseFloat(rowData.otherDeduction) > 0 && rowData.otherDeductionRemarks == "" ? "Deduction Remarks cannot be empty" : "", },
                                                    { title: "Net Pay", field: "netPay", editable: 'never', render: (row) => (row.netPay ? "Rs. " + numberWithCommas(row.netPay) : "Rs. 0") },
                                                    {
                                                        title: "MG Earning", field: "Payout", editable: 'never', render: (row) => {
                                                            if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                                                                return "Rs. " + numberWithCommas(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
                                                            } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                                                                return "Rs. -" + numberWithCommas((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
                                                            } else {
                                                                return "Rs. 0"
                                                            }
                                                        }
                                                    },
                                                    { title: "Current Status", field: "hold", editable: 'never', render: (row) => row.hold == 1 ? 'On-Hold' : 'Under Process' },
                                                    {
                                                        title: "Status", field: "hold", editable: 'never',
                                                        hidden: payoutDataStatus && user.user.roles == 3,
                                                        render: (row) =>
                                                            payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && user.user.roles == 7 ? <Button onClick={() => { toggleHoldModal(row) }} className={`btn text-white btn-${row.hold == 1 ? 'primary' : 'primary'}`}>{row.hold == 1 ? 'Procss' : 'Hold'}</Button> : <Button onClick={() => { toggleHoldModal(row) }} disabled={true} className={`btn text-white btn-${row.hold == 1 ? 'primary' : 'primary'}`}>{row.hold == 1 ? 'Procss' : 'Hold'}</Button>
                                                    },
                                                ]}
                                                editable={
                                                    (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.RetainerPayoutDataStatus && (payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_approval.state || payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.init.state) && user.user.roles == 7) ? {
                                                        onRowUpdate: (newData, oldData) =>
                                                            new Promise((resolve, reject) => {
                                                                setTimeout(() => {
                                                                    const dataUpdate = [...TempData];
                                                                    const index = oldData.tableData.id;
                                                                    var doctorFee = parseFloat(newData.Payout)
                                                                    if (newData.otherDeduction) {
                                                                        doctorFee = (parseFloat(doctorFee)) - (parseFloat(newData.otherDeduction))
                                                                    }
                                                                    if (newData.otherEarning) {
                                                                        doctorFee = (parseFloat(doctorFee)) + (parseFloat(newData.otherEarning))
                                                                    }
                                                                    dataUpdate[index] = newData;
                                                                    dataUpdate[index].netPay = doctorFee
                                                                    console.log(oldData, newData, 'exception item update')
                                                                    if (newData.Concatenate == '') {
                                                                        setConfirmDialog({
                                                                            isOpen: true,
                                                                            title: "Alert",
                                                                            subtitle: "Please Add Remarks",
                                                                        });
                                                                        reject()
                                                                        return;
                                                                    } else {
                                                                        updateDoctorItemData(newData, oldData)
                                                                        setTempData([...dataUpdate]);
                                                                        resolve();
                                                                    }

                                                                }, 1000)
                                                            }),
                                                    } : {}}
                                                data={TempData}
                                                options={{
                                                    sorting: true,
                                                    // exportButton: true,
                                                    exportAllData: true,
                                                    filtering: true,
                                                    pageSize: 5,
                                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                    search: true,
                                                }}
                                                icons={{
                                                    Filter: "filter",
                                                }}
                                                actions={[
                                                    {
                                                        icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                        tooltip: "Export to Excel",
                                                        onClick: () => downloadExcel(),
                                                        isFreeAction: true
                                                    },
                                                    {
                                                        icon: 'info',
                                                        tooltip: 'View Details',
                                                        onClick: (event, rowData) => getDoctorData(rowData)
                                                    }
                                                ]}
                                                onSelectionChange={(rows) => {
                                                    setRowSelection(true);
                                                    setChangeRowValue(rows);
                                                }}
                                                title="Payout Report"
                                            />
                                            {
                                                doctor && doctor.name && doctor.email ?

                                                    <TabContext value={doctorPayoutCalculationTab}>
                                                        <div style={{
                                                            margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                                                            color: "white"
                                                        }}>Payout Report {doctor && doctor.name ? doctor.name : ""}</div>
                                                        <TabList
                                                            onChange={handleChangeDoctorPayoutCalculationTab}
                                                            aria-label="simple tabs example"
                                                            variant="scrollable"
                                                        >
                                                            <Tab label="IPD" value="IPD" />
                                                            <Tab label="OPD" value="OPD" />
                                                        </TabList>
                                                        <TabPanel value="IPD">
                                                            {iPDetails && iPDetails.length > 0 ?
                                                                <MaterialTable
                                                                    columns={[
                                                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                                        {
                                                                            title: "IP No",
                                                                            field: "ip",
                                                                            editable: 'never',
                                                                        },
                                                                        { title: "Bill No", field: "BillNo", editable: 'never' },
                                                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                                        { title: "Bill Time", field: "BillTime", editable: 'never' },
                                                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                                        {
                                                                            title: "Age", field: "age", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Gender", field: "gender", editable: 'never',
                                                                        },
                                                                        { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                                        {
                                                                            title: "Admit Group", field: "admitGroup", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Admission Purpose", field: "admissionPurpose", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Admission Date", field: "AdmissionDate", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Discharge Date", field: "DischargeDate", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "LOS(InDays)", field: "LOS", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                                                "IP Physician Consulting": "IP Physician Consulting",
                                                                                "OT Surgeon Charges": "OT Surgeon Charges",
                                                                                "Radiology": "Radiology",
                                                                                "Non OT Procedures": "Non OT Procedures",
                                                                                "Anesthesia Services": "Anesthesia Services",
                                                                                "Other Charges": "Other Charges",
                                                                                "Pharmacy": "Pharmacy",
                                                                                "Lab": "Lab",
                                                                                "Registration Charge": "Registration Charge",
                                                                                "Care Team": "Care Team",
                                                                                "Procedure Charge": "Procedure Charge",
                                                                                "Bed Charges": "Bed Charges",
                                                                            }
                                                                        },
                                                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                                                        {
                                                                            title: "Bed Category", field: "bedCategory", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Bill Type", field: "billType", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Package Name", field: "packageName", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Package Amount", field: "PackageAmount", editable: 'never',
                                                                            render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                                                                        },
                                                                        {
                                                                            title: "Category", field: "insuranceCategory", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "TPA", field: "tpaName", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Insurance", field: "insurance", editable: 'never',
                                                                        },
                                                                        {
                                                                            title: "Corporate Name", field: "organisation", editable: 'never',
                                                                        },
                                                                        { title: "User", field: "User", editable: 'never' },
                                                                        { title: "Month", field: "PaymentMonth", editable: 'never' },
                                                                        {
                                                                            title: "ERP LOS", field: "erpLos", editable: 'never',
                                                                            lookup: {
                                                                                "Gynec": "Gynec",
                                                                                "Delivery": "Delivery",
                                                                                "Pead": "Pead",
                                                                                "Fertility": "Fertility",
                                                                            },
                                                                        },
                                                                        {
                                                                            title: "Pharmacy", field: "PharmacyTotal", editable: 'never',
                                                                            render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                                                                        },
                                                                        { title: "Discount", field: "Discount", editable: 'never' },
                                                                        {
                                                                            title: "Lab", field: "Lab", editable: 'never',
                                                                            render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                                                                        },
                                                                        {
                                                                            title: "Radiology", field: "Radiology", editable: 'never',
                                                                            render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                                                                        },
                                                                        {
                                                                            title: "Other Deduction", field: "otherDeduction", editable: 'never',
                                                                            render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                                                                        },
                                                                        { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                                                        {
                                                                            title: "Package Inclusion", field: "packageInclusion",
                                                                        },
                                                                        { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                                                        {
                                                                            title: "Nature", field: "NatureofDoctors",
                                                                        },
                                                                        {
                                                                            title: "Terms", field: "commercialTerm",
                                                                        },
                                                                        { title: "Qty", field: "Count" },
                                                                        { title: "Amount", field: "doctorAmount" },
                                                                        { title: "Ratio", field: "Ratio" },
                                                                        { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                                                        { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                                                        // { title: "RD Remarks ", field: "Concatenate" },
                                                                    ]}
                                                                    data={iPDetails}
                                                                    options={{
                                                                        sorting: true,
                                                                        // exportButton: true,
                                                                        exportAllData: true,
                                                                        fixedColumns: {
                                                                            top: 1,
                                                                            right: 0
                                                                        },
                                                                        maxBodyHeight: '500px',
                                                                        filtering: true,
                                                                        // filterRowStyle: {
                                                                        //     position: "sticky",
                                                                        //     top: 60,
                                                                        //     background: "white",
                                                                        //     zIndex: 5 /* optionally */
                                                                        // },
                                                                        pageSize: 5,
                                                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                                        search: true,
                                                                    }}
                                                                    icons={{
                                                                        Filter: "filter",
                                                                    }}
                                                                    actions={[
                                                                        {
                                                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                                            tooltip: "Export to Excel",
                                                                            onClick: () => downloadDoctorIPReport(),
                                                                            isFreeAction: true
                                                                        }
                                                                    ]}
                                                                    onSelectionChange={(rows) => {
                                                                        setRowSelection(true);
                                                                        setChangeRowValue(rows);
                                                                    }}
                                                                    title={"Doctor IP Payout Report " + (doctor && doctor.name ? doctor.name : "")}
                                                                />
                                                                :
                                                                "No Data Found"
                                                            }
                                                        </TabPanel>
                                                        <TabPanel value="OPD">
                                                            {oPDetails && oPDetails.length > 0 ?
                                                                <MaterialTable
                                                                    columns={[
                                                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                                        { title: "Bill No", field: "BillNo", editable: 'never' },
                                                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                                        { title: "Bill Time", field: "BillTime", editable: 'never' },
                                                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                                        { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                                        {
                                                                            title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                                                "OP Physician Consulting": "OP Physician Consulting",
                                                                                "Radiology": "Radiology",
                                                                                "Non OT Procedures": "Non OT Procedures",
                                                                                "Other Charges": "Other Charges",
                                                                                "Pharmacy": "Pharmacy",
                                                                                "Lab": "Lab",
                                                                                "Registration Charge": "Registration Charge",
                                                                                "Medical Equipments": "Medical Equipments",
                                                                                "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                                                "Ear Piercing - Product": "Ear Piercing - Product",
                                                                                "Ear Piercing - Service": "Ear Piercing - Service",
                                                                            }
                                                                        },
                                                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                                                        { title: "User", field: "User", editable: 'never' },
                                                                        { title: "Month", field: "PaymentMonth", editable: 'never' },
                                                                        { title: "Discount", field: "Discount", editable: 'never' },
                                                                        { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                                                        { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                                                        {
                                                                            title: "Terms", field: "commercialTerm",
                                                                        },
                                                                        { title: "Qty", field: "Count" },
                                                                        { title: "Amount", field: "doctorAmount" },
                                                                        { title: "Ratio", field: "Ratio" },
                                                                        { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                                                        { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                                                        // { title: "RD Remarks ", field: "Concatenate" },
                                                                    ]}
                                                                    data={oPDetails}
                                                                    options={{
                                                                        sorting: true,
                                                                        // exportButton: true,
                                                                        exportAllData: true,
                                                                        fixedColumns: {
                                                                            top: 1,
                                                                            right: 0
                                                                        },
                                                                        maxBodyHeight: '500px',
                                                                        filtering: true,
                                                                        pageSize: 5,
                                                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                                        search: true,
                                                                    }}
                                                                    icons={{
                                                                        Filter: "filter",
                                                                    }}
                                                                    actions={[
                                                                        {
                                                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                                            tooltip: "Export to Excel",
                                                                            onClick: () => downloadDoctorOPReport(),
                                                                            isFreeAction: true
                                                                        }
                                                                    ]}
                                                                    onSelectionChange={(rows) => {
                                                                        setRowSelection(true);
                                                                        setChangeRowValue(rows);
                                                                    }}
                                                                    title={"Doctor OP Payout Report " + (doctor && doctor.name ? doctor.name : "")}
                                                                />
                                                                :
                                                                "No Data Found"
                                                            }
                                                        </TabPanel>
                                                    </TabContext>
                                                    :
                                                    ""
                                            }
                                        </>
                                        :
                                        "No Data Found"
                                    }
                                </TabPanel>
                                <TabPanel value="Consolidated Adjustments" >
                                    <TabContext value={doctorPayoutAdjustmentCalculationTab}>
                                        <div style={{
                                            margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                                            color: "white"
                                        }}>Payout Report {doctor && doctor.name ? doctor.name : ""}</div>
                                        <TabList
                                            onChange={handleChangeDoctorPayoutAdjustmentCalculationTab}
                                            aria-label="simple tabs example"
                                            variant="scrollable"
                                        >
                                            <Tab label="IPD" value="IPD" />
                                            <Tab label="OPD" value="OPD" />
                                            <Tab label="Consolidated" value="Consolidated" />
                                        </TabList>
                                        <TabPanel value="IPD">
                                            {IPPayoutReports && IPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '').length > 0 ?
                                                <MaterialTable
                                                    columns={[
                                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                        { title: "IPNo", field: "ip", editable: 'never' },
                                                        { title: "Bill No", field: "BillNo", editable: 'never' },
                                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                        { title: "Age", field: "age", editable: 'never' },
                                                        { title: "Gender", field: "gender", editable: 'never' },
                                                        { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                        { title: "Admit Group", field: "admitGroup", editable: 'never' },
                                                        { title: "Admission Purpose", field: "admissionPurpose", editable: 'never' },
                                                        { title: "LOS(InDays)", field: "LOS", editable: 'never' },
                                                        {
                                                            title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                                "IP Physician Consulting": "IP Physician Consulting",
                                                                "OT Surgeon Charges": "OT Surgeon Charges",
                                                                "Radiology": "Radiology",
                                                                "Non OT Procedures": "Non OT Procedures",
                                                                "Anesthesia Services": "Anesthesia Services",
                                                                "Other Charges": "Other Charges",
                                                                "Pharmacy": "Pharmacy",
                                                                "Lab": "Lab",
                                                                "Registration Charge": "Registration Charge",
                                                                "Care Team": "Care Team",
                                                                "Procedure Charge": "Procedure Charge",
                                                                "Bed Charges": "Bed Charges",
                                                            }
                                                        },
                                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                                        { title: "Bed Category", field: "bedCategory", editable: 'never' },
                                                        { title: "Bill Type", field: "billType", editable: 'never' },
                                                        { title: "Package Name", field: "packageName", editable: 'never' },
                                                        { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                                                        { title: "Category", field: "Category", editable: 'never' },
                                                        { title: "TPA", field: "tpaName", editable: 'never' },
                                                        { title: "Insurance", field: "insurance", editable: 'never' },
                                                        { title: "Corporate Name", field: "organisation", editable: 'never' },
                                                        { title: "User", field: "User", editable: 'never' },
                                                        { title: "Category", field: "ipGroup", editable: 'never' },
                                                        { title: "Sub Category", field: "ipSubGroup", editable: 'never' },
                                                        {
                                                            title: "ERP LOS", field: "erpLos", editable: 'never',
                                                            lookup: {
                                                                "Gynec": "Gynec",
                                                                "Delivery": "Delivery",
                                                                "Pead": "Pead",
                                                                "Fertility": "Fertility",
                                                            },
                                                        },
                                                        { title: "Pharmacy", field: "PharmacyTotal", editable: 'never', render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0 },
                                                        { title: "Discount", field: "Discount", editable: 'never' },
                                                        { title: "Lab", field: "Lab", editable: 'never', render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0 },
                                                        { title: "Radiology", field: "Radiology", editable: 'never', render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0 },
                                                        { title: "Other Deduction", field: "otherDeduction", editable: 'never', render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0 },
                                                        { title: "Epidural Charges", field: "epiduralCharges", editable: 'never', render: (row) => row.epiduralCharges ? numberWithCommas(parseFloat(row.epiduralCharges).toFixed()) : 0 },
                                                        { title: "Safety Consumable", field: "safetyConsumable", editable: 'never', render: (row) => row.safetyConsumable ? numberWithCommas(parseFloat(row.safetyConsumable).toFixed()) : 0 },
                                                        { title: "Net Bill (Bill Report)", field: "Gross", editable: 'never', render: (row) => row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0 },
                                                        { title: "Net Bill (Calculated)", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                                        { title: "Item Gross", field: "itemGross", editable: 'never', render: (row) => row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0 },
                                                        { title: "Item Net", field: "itemNet", editable: 'never', render: (row) => row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0 },
                                                        { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                                        { title: "Doctor LTID", field: "CONDUCTED_BY_LOGIN_ID", validate: rowData => rowData.CONDUCTED_BY_LOGIN_ID !== '' && rowData.CONDUCTED_BY_LOGIN_ID !== null ? "" : "Doctor LTID cannot be empty" },
                                                        {
                                                            title: "Nature", field: "NatureofDoctors",
                                                            lookup: {
                                                                "Surgeon Fee": "Surgeon Fee",
                                                                "IP Visit Fee": "IP Visit Fee",
                                                                "NICU": "NICU",
                                                                "Radiology": "Radiology",
                                                                "Non OT Procedures": "Non OT Procedures",
                                                                "Anesthesia Services": "Anesthesia Services",
                                                                "Other Charges": "Other Charges",
                                                                "Pharmacy": "Pharmacy",
                                                                "Lab": "Lab",
                                                                "Registration Charge": "Registration Charge",
                                                                "Care Team": "Care Team",
                                                                "Procedure Charge": "Procedure Charge",
                                                                "Bed Charges": "Bed Charges",
                                                            },
                                                        },
                                                        { title: "Terms", field: "commercialTerm" },
                                                        { title: "Qty", field: "Count" },
                                                        { title: "Amount", field: "doctorAmount" },
                                                        { title: "Ratio", field: "Ratio" },
                                                        { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                                        { title: "DP Team Remarks ", field: "Concatenate", editable: 'never' },
                                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                                        { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                                    ]}
                                                    data={IPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '')}
                                                    actions={[
                                                        {
                                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                            tooltip: "Export to Excel",
                                                            onClick: () => downloadAdjustmentSummaryIPCalculatedDataExcel(),
                                                            isFreeAction: true
                                                        }
                                                    ]}
                                                    options={{
                                                        sorting: true,
                                                        fixedColumns: {
                                                            top: 1,
                                                            right: 0
                                                        },
                                                        maxBodyHeight: '500px',
                                                        filtering: true,
                                                        exportButton: false,
                                                        // exportAllData: true,
                                                        pageSize: 5,
                                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                        search: true,
                                                    }}
                                                    icons={{
                                                        Filter: "filter",
                                                    }}
                                                    title="IP-Payout"
                                                />
                                                :
                                                "No Data Found"
                                            }
                                        </TabPanel>
                                        <TabPanel value="OPD">
                                            {OPPayoutReports && OPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '').length > 0 ?
                                                <MaterialTable
                                                    columns={[
                                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                                        {
                                                            title: "Bill No",
                                                            editable: 'never',
                                                            field: "BillNo",
                                                            render: (row) => (
                                                                <div
                                                                    style={{ cursor: "pointer", color: "red" }}
                                                                    onClick={() => alert(row.BillNo)}
                                                                >
                                                                    {row.BillNo}
                                                                </div>
                                                            ),
                                                        },
                                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                                        {
                                                            title: "Bill Time", editable: 'never',
                                                            field: "BillTime",
                                                            render: (row) =>
                                                                <div>{row.BillTime == null ? moment(row.BillTime).format("LT") : row.BillTime}</div>,
                                                        },
                                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                                        {
                                                            title: "Item Group Name", field: "ItemGroupName", editable: 'never', lookup: {
                                                                "OP Physician Consulting": "OP Physician Consulting",
                                                                "Radiology": "Radiology",
                                                                "Non OT Procedures": "Non OT Procedures",
                                                                "Other Charges": "Other Charges",
                                                                "Pharmacy": "Pharmacy",
                                                                "Lab": "Lab",
                                                                "Registration Charge": "Registration Charge",
                                                                "Medical Equipments": "Medical Equipments",
                                                                "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                                "Ear Piercing - Product": "Ear Piercing - Product",
                                                                "Ear Piercing - Service": "Ear Piercing - Service",
                                                            }
                                                        },
                                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                                        { title: "Gross", field: "Gross", editable: 'never' },
                                                        { title: "Tax", field: "Tax", editable: 'never' },
                                                        { title: "Package Overrun", field: "PackageOverrun", editable: 'never' },
                                                        { title: "Discount", field: "Discount", editable: 'never' },
                                                        { title: "Discount Type", field: "DiscountType", editable: 'never' },
                                                        {
                                                            title: "Admin Fee",
                                                            field: "AdminFee", editable: 'never',
                                                            render: (row) =>
                                                                row.ItemName.includes('Consultation') ? row.consultation : row.ItemName.includes('Follow') ? row.followup : 0,
                                                        },
                                                        {
                                                            title: "Type",
                                                            field: "doctorType", editable: 'never',
                                                        },
                                                        {
                                                            title: "OP/IPD",
                                                            field: "OPIPD", editable: 'never',
                                                            render: (row) =>
                                                                row.OPIPD == null ? <div>OPD</div> : <div>{row.OPIPD}</div>,
                                                        },
                                                        {
                                                            title: "Ordering Physician",
                                                            field: "OrderingPhysician", editable: 'never',
                                                        },
                                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                                        { title: "User", field: "User", editable: 'never' },
                                                        { title: "Net", field: "Net", editable: 'never' },
                                                        {
                                                            title: "Conducted By",
                                                            field: "ConductedBy",
                                                            render: (row) =>
                                                                row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                                                        },
                                                        {
                                                            title: "Conducted By Login Id",
                                                            field: "CONDUCTED_BY_LOGIN_ID",
                                                            render: (row) =>
                                                                row.CONDUCTED_BY_LOGIN_ID == null ? <div>NA</div> : <div>{row.CONDUCTED_BY_LOGIN_ID}</div>,
                                                        },
                                                        {
                                                            title: "Doctor Payout",
                                                            field: "doctorFee",
                                                            render: (row) =>
                                                                row.doctorFee != null ? (
                                                                    <div style={{ cursor: "pointer", color: "green" }}>
                                                                        {row.doctorFee.toFixed(2)}
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ cursor: "pointer", color: "red" }}>
                                                                        {row.doctorFee}
                                                                    </div>
                                                                ),
                                                            type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0
                                                        },
                                                        { title: "DP Team Remarks ", field: "Concatenate", editable: "never" },
                                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && user.user.roles != 7 && rowData.GLCoe !== null ? "" : "Remarks cannot be empty", },
                                                        {
                                                            title: "Share",
                                                            field: "Ratio",
                                                            type: 'numeric',
                                                            render: (row) =>
                                                                row.Ratio != null ? (
                                                                    <div >
                                                                        {row.Ratio}
                                                                    </div>
                                                                ) : (
                                                                    <div >
                                                                        NA
                                                                    </div>
                                                                ),
                                                            validate: rowData => parseFloat(rowData.Ratio) < 0 ? "Share cannot be less than 0" : "", render: (row) => row.Ratio ? numberWithCommas(parseFloat(row.Ratio).toFixed()) : 0
                                                        },
                                                        {
                                                            title: "Fixed Amount",
                                                            field: "doctorAmount",
                                                            type: 'numeric',
                                                            render: (row) =>
                                                                row.doctorAmount != null ? (
                                                                    <div >
                                                                        {row.doctorAmount}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        0
                                                                    </div>
                                                                ),
                                                            validate: rowData => parseFloat(rowData.doctorAmount) < 0 ? "Fixed Amount cannot be less than 0" : "", render: (row) => row.doctorAmount ? numberWithCommas(parseFloat(row.doctorAmount).toFixed()) : 0
                                                        },
                                                        { title: "Center", field: "Location", editable: 'never' },
                                                        { title: "Exception Issue", field: "vlookup", editable: 'never' },
                                                    ]}
                                                    actions={[
                                                        {
                                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                            tooltip: "Export to Excel",
                                                            onClick: () => downloadAdjustmentSummaryOPCalculatedDataExcel(),
                                                            isFreeAction: true
                                                        }
                                                    ]}
                                                    data={OPPayoutReports.filter((ele) => ele.Concatenate !== '' || ele.GLCoe !== '')}
                                                    icons={{
                                                        Filter: "filter",
                                                    }}
                                                    options={{
                                                        sorting: true,
                                                        fixedColumns: {
                                                            top: 1,
                                                            right: 0
                                                        },
                                                        maxBodyHeight: '500px',
                                                        filtering: true,
                                                        exportButton: false,
                                                        rowStyle: (rowData) => ({
                                                            backgroundColor: rowData.status === "Yes" ? "#ad4079" : "#FFF",
                                                        }),
                                                        pageSize: 5,
                                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                        search: true,
                                                        exportAllData: true,
                                                    }}
                                                    title="OP-Payout"
                                                />
                                                :
                                                "No Data Found"
                                            }
                                        </TabPanel>
                                        <TabPanel value="Consolidated">
                                            {TempData && TempData.length > 0 ?
                                                <>
                                                    <MaterialTable
                                                        columns={[
                                                            { title: "D365 ID", field: "d365_id", editable: 'never', render: (row) => row.d365_id ? row.d365_id : '-' },
                                                            {
                                                                title: "Doctor Name",
                                                                field: "name",
                                                                editable: 'never',
                                                                render: (row) => row.name.includes('Dr. ') ? row.name : "Dr. " + row.name
                                                            },
                                                            { title: "Doctor LT ID", field: "email", editable: 'never', },
                                                            { title: "PAN", field: "panno", editable: 'never', },
                                                            { title: "Date of Joining", field: "doj", editable: 'never', },
                                                            { title: "Previous Agreement Date", field: "doj", editable: 'never' },
                                                            { title: "Current Agreement Date", field: "doj", editable: 'never' },
                                                            {
                                                                title: "Doctor Type", field: "category",
                                                                editable: 'never',
                                                                lookup: {
                                                                    "Full Time Consultant": "Full Time Consultant",
                                                                    "Visiting Consultant": "Visiting Consultant",
                                                                    "Retainer": "Retainer",
                                                                    "Minimum Guarentee(MG)": "Minimum Guarentee(MG)",
                                                                }
                                                            },
                                                            { title: "MG / Month", field: "minguranteepermonth", editable: 'never', render: (row) => (row.minguranteepermonth ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "Rs. 0") },
                                                            { title: "Fixed", field: "fixedAmount", editable: 'never', render: (row) => (row.fixedAmount ? "Rs. " + numberWithCommas(row.fixedAmount) : "Rs. 0") },
                                                            { title: "OPD Payout", field: "OPPayout", editable: 'never', render: (row) => (row.OPPayout ? "Rs. " + numberWithCommas(row.OPPayout) : "Rs. 0") },
                                                            { title: "OPD Revenue", field: "OPDRevenue", editable: 'never', render: (row) => (row.OPDRevenue ? "Rs. " + numberWithCommas(row.OPDRevenue) : "Rs. 0") },
                                                            { title: "OPD Revenue (%)", field: "OPDRevenue", editable: 'never', render: (row) => (row.OPDRevenue ? ((Math.round((row.OPPayout / row.OPDRevenue) * 100))) + " %" : 0) },
                                                            { title: "IPD Payout", field: "IPPayout", editable: 'never', render: (row) => (row.IPPayout ? "Rs. " + numberWithCommas(row.IPPayout) : "Rs. 0") },
                                                            { title: "IPD Revenue", field: "IPDRevenue", editable: 'never', render: (row) => (row.IPDRevenue ? "Rs. " + numberWithCommas(row.IPDRevenue) : "Rs. 0") },
                                                            { title: "IPD Revenue (%)", field: "IPDRevenue", editable: 'never', render: (row) => (row.IPDRevenue ? ((Math.round((row.IPPayout / row.IPDRevenue) * 100))) + " %" : 0) },
                                                            { title: "Calculated Payout", field: "Payout", editable: 'never', render: (row) => (row.Payout ? "Rs. " + numberWithCommas(row.Payout) : "Rs. 0") },
                                                            { title: "Addition", field: "otherEarning", type: 'numeric', validate: rowData => parseFloat(rowData.otherEarning) < 0 ? "Addition cannot be less than 0" : "", },
                                                            { title: "Addition Remarks", field: "otherEarningRemarks", validate: rowData => parseFloat(rowData.otherEarning) > 0 && rowData.otherEarningRemarks == "" ? "Addition Remarks cannot be empty" : "", },
                                                            { title: "Deduction", field: "otherDeduction", type: 'numeric', validate: rowData => parseFloat(rowData.otherDeduction) < 0 ? "Deduction cannot be less than 0" : "", },
                                                            { title: "Deduction Remarks", field: "otherDeductionRemarks", validate: rowData => parseFloat(rowData.otherDeduction) > 0 && rowData.otherDeductionRemarks == "" ? "Deduction Remarks cannot be empty" : "", },
                                                            { title: "Net Pay", field: "netPay", editable: 'never', render: (row) => (row.netPay ? "Rs. " + numberWithCommas(row.netPay) : "Rs. 0") },
                                                            {
                                                                title: "MG Earning", field: "Payout", editable: 'never', render: (row) => {
                                                                    if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                                                                        return "Rs. " + numberWithCommas(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
                                                                    } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                                                                        return "Rs. -" + numberWithCommas((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
                                                                    } else {
                                                                        return "Rs. 0"
                                                                    }
                                                                }
                                                            },
                                                            { title: "Current Status", field: "hold", editable: 'never', render: (row) => row.hold == 1 ? 'On-Hold' : 'Under Process' },
                                                            {
                                                                title: "Status", field: "hold", editable: 'never',
                                                                hidden: payoutDataStatus && user.user.roles == 3,
                                                                render: (row) =>
                                                                    payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && user.user.roles == 7 ? <Button onClick={() => { toggleHoldModal(row) }} className={`btn text-white btn-${row.hold == 1 ? 'primary' : 'primary'}`}>{row.hold == 1 ? 'Procss' : 'Hold'}</Button> : <Button onClick={() => { toggleHoldModal(row) }} disabled={true} className={`btn text-white btn-${row.hold == 1 ? 'primary' : 'primary'}`}>{row.hold == 1 ? 'Procss' : 'Hold'}</Button>
                                                            },
                                                        ]}
                                                        data={TempData.filter(ele => ele.otherDeductionRemarks !== '' || ele.otherEarningRemarks !== '')}
                                                        options={{
                                                            sorting: true,
                                                            // exportButton: true,
                                                            exportAllData: true,
                                                            filtering: true,
                                                            pageSize: 5,
                                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                            search: true,
                                                        }}
                                                        icons={{
                                                            Filter: "filter",
                                                        }}
                                                        actions={[
                                                            {
                                                                icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                                tooltip: "Export to Excel",
                                                                onClick: () => downloadRemarksExcel(),
                                                                isFreeAction: true
                                                            },
                                                        ]}
                                                        title="Payout Report"
                                                    />
                                                </>
                                                :
                                                "No Data Found"
                                            }
                                        </TabPanel>
                                    </TabContext>
                                </TabPanel>
                                <TabPanel value="Retainer">
                                    {RetainerPayoutReports && RetainerPayoutReports.length > 0 ?
                                        <MaterialTable
                                            columns={[
                                                { title: "D365 ID", field: "d365_id", editable: 'never', render: (row) => row.d365_id ? row.d365_id : '-' },
                                                { title: "Doctor name", field: "ConductedBy", editable: 'never' },
                                                { title: "PAN", field: "panno", editable: 'never' },
                                                { title: "Center", field: "Location", editable: 'never' },
                                                { title: "Date Of Joining", field: "doj" },
                                                { title: "Previous Agreement Date", field: "doj", editable: 'never' },
                                                { title: "Current Agreement Date", field: "doj", editable: 'never' },
                                                { title: "Doctor Amount", field: "fixedAmount", editable: 'never' },
                                                { title: "Total Days", field: "totalDays", editable: 'never' },
                                                { title: "LOP", field: "LOS", type: 'numeric', validate: rowData => parseFloat(rowData.LOS) < 0 ? "LOP cannot be less than 0" : "", },
                                                { title: "OT", field: "OT", type: 'numeric', validate: rowData => parseFloat(rowData.OT) < 0 ? "OT cannot be less than 0" : "", render: (row) => row.OT ? row.OT : 0 },
                                                { title: "Attendance", field: "Attendance", editable: 'never', render: (row) => (row.totalDays - parseFloat(row.LOS ? row.LOS : 0) + parseFloat(row.OT ? row.OT : 0)) },
                                                { title: "Doctor Fee", field: "doctorFee", editable: 'never' },
                                                { title: "LOP Remarks", field: "LOPRemarks", validate: rowData => parseFloat(rowData.LOS) > 0 && rowData.LOPRemarks == "" ? "LOP Remarks can't be empty" : "", },
                                                { title: "OT Remarks", field: "OTRemarks", validate: rowData => parseFloat(rowData.OT) > 0 && rowData.OTPRemarks == "" ? "OT Remarks can't be empty" : "", },
                                                { title: "Other Deduction", field: "otherDeduction", type: 'numeric', validate: rowData => parseFloat(rowData.otherDeduction) < 0 ? "Other Deduction cannot be less than 0" : "", },
                                                { title: "Other Deduction Remarks", field: "otherDeductionRemarks", validate: rowData => parseFloat(rowData.otherDeduction) > 0 && rowData.otherDeductionRemarks == "" ? "Other Deduction Remarks cannot be empty" : "", },
                                                { title: "Other Earning", field: "otherEarning", type: 'numeric', validate: rowData => parseFloat(rowData.otherEarning) < 0 ? "Other Earning cannot be less than 0" : "", },
                                                { title: "Other Earning Remarks", field: "otherEarningRemarks", validate: rowData => parseFloat(rowData.otherEarning) > 0 && rowData.otherEarningRemarks == "" ? "Other Earning Remarks cannot be empty" : "", },
                                                { title: "Gross Earning", field: "doctorFee", editable: 'never' },
                                                { title: "TDS(%)", field: "TDS", editable: 'never', render: (row) => row.TDS ? row.TDS : 10 },
                                                { title: "Net Pay", field: "NetPay", editable: 'never' },
                                                { title: "Current Status", field: "onHold", editable: 'never', render: (row) => row.onHold ? 'On-Hold' : 'Under Process' },
                                            ]}
                                            options={{
                                                sorting: true,
                                                // exportButton: true,
                                                exportAllData: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                            }}
                                            data={RetainerPayoutReports}
                                            actions={[
                                                {
                                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadRetainerExcel(),
                                                    isFreeAction: true
                                                }
                                            ]}
                                            title="Retainer Details"
                                        />
                                        :
                                        "No Data Found"
                                    }
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </TabPanel>
                </TabContext>
                {/* <div style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>Payout Report</div> */}

                {user.user.roles == 20 ?
                    <>
                        <MaterialTable
                            columns={[
                                { title: "Start Date", field: "startDate" },
                                { title: "End Date", field: "endDate" },
                                { title: "Center", field: "center" },
                                {
                                    title: "Action", field: "Action", filtering: false, render: (row) =>
                                        <IconButton style={{ color: "#810e36" }} aria-label="close" onClick={() => getPayoutReports(row)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                },
                            ]}
                            data={payoutReq}
                            options={{
                                sorting: true,
                                fixedColumns: {
                                    top: 1,
                                    right: 0
                                },
                                maxBodyHeight: '500px',
                                filtering: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                search: true,
                            }}
                            icons={{
                                Filter: "filter",
                            }}
                            title="Generated Payouts"
                        />
                        {user.user.activityAssigments.filter((elem) => elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) ?
                            <>
                                <>
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "Split",
                                                field: "Action",
                                                filtering: false,
                                                hidden: (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                                render: (row) => (
                                                    <Tooltip title="Split Record">
                                                        <IconButton>
                                                            <CallSplitIcon
                                                                style={{
                                                                    transform: "rotate(90deg)",
                                                                    color: "#810e36",
                                                                }}
                                                                onClick={() => {
                                                                    setSplitDialog(true);
                                                                    onSplitRow(row);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                ),
                                            },
                                            { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            {
                                                title: "IP No",
                                                field: "ip",
                                                editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Bill No", field: "BillNo", editable: 'never' },
                                            { title: "Bill Date", field: "BillDate", editable: 'never' },
                                            { title: "Bill Time", field: "BillTime", editable: 'never' },
                                            { title: "Patient Name", field: "PatientName", editable: 'never' },
                                            {
                                                title: "Age", field: "age", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Gender", field: "gender", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                            { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                            {
                                                title: "Admit Group", field: "admitGroup", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Admission Purpose", field: "admissionPurpose", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Admission Date", field: "AdmissionDate", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Discharge Date", field: "DischargeDate", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "LOS(InDays)", field: "LOS", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            }, {
                                                title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                    "IP Physician Consulting": "IP Physician Consulting",
                                                    "OT Surgeon Charges": "OT Surgeon Charges",
                                                    "Radiology": "Radiology",
                                                    "Non OT Procedures": "Non OT Procedures",
                                                    "Anesthesia Services": "Anesthesia Services",
                                                    "Other Charges": "Other Charges",
                                                    "Pharmacy": "Pharmacy",
                                                    "Lab": "Lab",
                                                    "Registration Charge": "Registration Charge",
                                                    "Care Team": "Care Team",
                                                    "Procedure Charge": "Procedure Charge",
                                                    "Bed Charges": "Bed Charges",
                                                }
                                            },
                                            { title: "Item Name", field: "ItemName", editable: 'never' },
                                            {
                                                title: "Bed Category", field: "bedCategory", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Bill Type", field: "billType", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Package Name", field: "packageName", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Package Amount", field: "PackageAmount", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false, render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                                            },
                                            {
                                                title: "Category", field: "insuranceCategory", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "TPA", field: "tpaName", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Insurance", field: "insurance", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Corporate Name", field: "organisation", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "User", field: "User", editable: 'never' },
                                            { title: "Month", field: "PaymentMonth", editable: 'never' },
                                            {
                                                title: "ERP LOS", field: "erpLos", editable: 'never',
                                                lookup: {
                                                    "Gynec": "Gynec",
                                                    "Delivery": "Delivery",
                                                    "Pead": "Pead",
                                                    "Fertility": "Fertility",
                                                },
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Pharmacy", field: "PharmacyTotal", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                                            },
                                            { title: "Discount", field: "Discount", editable: 'never' },
                                            {
                                                title: "Lab", field: "Lab", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                                            },
                                            {
                                                title: "Radiology", field: "Radiology", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                                            },
                                            {
                                                title: "Other Deduction", field: "otherDeduction", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                                            },
                                            { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                            { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                            {
                                                title: "Nature", field: "NatureofDoctors",
                                                lookup: {
                                                    "Surgeon Fee": "Surgeon Fee",
                                                    "Anesthesia Services": "Anesthesia Services",
                                                    "IP Visit Fee": "IP Visit Fee",
                                                    "Radiology Fee": "Radiology Fee",
                                                    "NICU": "NICU",
                                                    "Non OT Procedures": "Non OT Procedures",
                                                    "Other Charges": "Other Charges",
                                                    "Additional Procedure": "Additional Procedure",
                                                },
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Terms", field: "commercialTerm",
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Qty", field: "Count" },
                                            { title: "Amount", field: "doctorAmount" },
                                            { title: "Ratio", field: "Ratio" },
                                            { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                            { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                            { title: "DP Team Remarks ", field: "Concatenate" },
                                        ]}
                                        data={IPPayoutReports}
                                        editable={user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles) ?
                                            (user.user.roles == 2 && payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction && ((payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles)))) ? {
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            const dataUpdate = [...IPPayoutReports];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            console.log(oldData, newData, 'exception item update')
                                                            if (newData.GLCoe == '') {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: "Alert",
                                                                    subtitle: "Please Add Remarks",
                                                                });
                                                                reject()
                                                                return;
                                                            } else {
                                                                updateBillItemData(newData, oldData)
                                                                setIPPayoutReports([...dataUpdate]);
                                                                resolve();
                                                            }

                                                        }, 1000)
                                                    }),
                                            } : {} : {}}
                                        options={{
                                            fixedColumns: {
                                                top: 1,
                                                right: 0
                                            },
                                            maxBodyHeight: '500px',
                                            filtering: true,
                                            sorting: true,
                                            // exportButton: true,
                                            // exportAllData: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        icons={{
                                            Filter: "filter",
                                        }}
                                        onSelectionChange={(rows) => {
                                            setRowSelection(true);
                                            setChangeRowValue(rows);
                                        }}
                                        title="IP Payout Details"
                                    />

                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "Split",
                                                field: "Action",
                                                filtering: false,
                                                hidden: (payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                                render: (row) => (
                                                    <Tooltip title="Split Record">
                                                        <IconButton>
                                                            <CallSplitIcon
                                                                style={{
                                                                    transform: "rotate(90deg)",
                                                                    color: "#810e36",
                                                                }}
                                                                onClick={() => {
                                                                    setSplitDialog(true);
                                                                    onSplitRow(row);
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                ),
                                            },
                                            { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                            {
                                                title: "IP No",
                                                field: "ip",
                                                editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Bill No", field: "BillNo", editable: 'never' },
                                            { title: "Bill Date", field: "BillDate", editable: 'never' },
                                            { title: "Bill Time", field: "BillTime", editable: 'never' },
                                            { title: "Patient Name", field: "PatientName", editable: 'never' },
                                            {
                                                title: "Age", field: "age", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Gender", field: "gender", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                            { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                            {
                                                title: "Admit Group", field: "admitGroup", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Admission Purpose", field: "admissionPurpose", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Admission Date", field: "AdmissionDate", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Discharge Date", field: "DischargeDate", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "LOS(InDays)", field: "LOS", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                    "OP Physician Consulting": "OP Physician Consulting",
                                                    "Radiology": "Radiology",
                                                    "Non OT Procedures": "Non OT Procedures",
                                                    "Other Charges": "Other Charges",
                                                    "Pharmacy": "Pharmacy",
                                                    "Lab": "Lab",
                                                    "Registration Charge": "Registration Charge",
                                                    "Medical Equipments": "Medical Equipments",
                                                    "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                    "Ear Piercing - Product": "Ear Piercing - Product",
                                                    "Ear Piercing - Service": "Ear Piercing - Service",
                                                }
                                            },
                                            { title: "Item Name", field: "ItemName", editable: 'never' },
                                            {
                                                title: "Bed Category", field: "bedCategory", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Bill Type", field: "billType", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Package Name", field: "packageName", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Package Amount", field: "PackageAmount", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false, render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                                            },
                                            {
                                                title: "Category", field: "insuranceCategory", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "TPA", field: "tpaName", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Insurance", field: "insurance", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Corporate Name", field: "organisation", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "User", field: "User", editable: 'never' },
                                            { title: "Month", field: "PaymentMonth", editable: 'never' },
                                            {
                                                title: "ERP LOS", field: "erpLos", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Pharmacy", field: "PharmacyTotal", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                                            },
                                            { title: "Discount", field: "Discount", editable: 'never' },
                                            {
                                                title: "Lab", field: "Lab", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                                            },
                                            {
                                                title: "Radiology", field: "Radiology", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                                            },
                                            {
                                                title: "Other Deduction", field: "otherDeduction", editable: 'never',
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                                render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                                            },
                                            { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                            { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                            {
                                                title: "Nature", field: "NatureofDoctors",
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            {
                                                title: "Terms", field: "commercialTerm",
                                                hidden: (row) => row.patientType == "OP" ? true : false,
                                            },
                                            { title: "Qty", field: "Count" },
                                            { title: "Amount", field: "doctorAmount" },
                                            { title: "Ratio", field: "Ratio" },
                                            { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                            { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                            // { title: "RD Remarks ", field: "Concatenate" },
                                        ]}
                                        data={OPPayoutReports}
                                        editable={user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles) ?
                                            (user.user.roles == 2 && payoutDataStatus && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction && ((payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles)))) ? {
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            const dataUpdate = [...OPPayoutReports];
                                                            const index = oldData.tableData.id;
                                                            dataUpdate[index] = newData;
                                                            console.log(oldData, newData, 'exception item update')
                                                            if (newData.GLCoe == '') {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: "Alert",
                                                                    subtitle: "Please Add Remarks",
                                                                });
                                                                reject()
                                                                return;
                                                            } else {
                                                                updateBillItemData(newData, oldData)
                                                                setIPPayoutReports([...dataUpdate]);
                                                                resolve();
                                                            }

                                                        }, 1000)
                                                    }),
                                            } : {} : {}}
                                        options={{
                                            // selection: payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approved.state ? true : false,
                                            sorting: true,
                                            // exportButton: true,
                                            // exportAllData: true,
                                            fixedColumns: {
                                                top: 1,
                                                right: 0
                                            },
                                            maxBodyHeight: '500px',
                                            filtering: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        icons={{
                                            Filter: "filter",
                                        }}
                                        onSelectionChange={(rows) => {
                                            setRowSelection(true);
                                            setChangeRowValue(rows);
                                        }}
                                        title="OP Payout Details"
                                    />
                                </>
                            </>
                            : ''}
                    </>
                    :
                    ""
                }

                {user.user.roles == 20 ?
                    <Paper
                        style={{ marginBottom: "10px", border: "1px solid #810e36" }}
                    >
                        <div style={{ padding: "8px", display: "flex" }}>
                            <Grid item xs={12} sm={12} xl={4} lg={4} md={4}>
                                <FormControl variant="outlined" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Select Center
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        // value={payoutDtls.Center}
                                        value={center}
                                        style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                                        // value={c}
                                        name="Center"
                                        onChange={selectedCenter}
                                        label="Select Center"
                                    >
                                        {centerName.length > 0 &&
                                            centerName.map((ele) => {
                                                return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                                            })}
                                        {/* {user.user.id == 401 ? (
                                        <MenuItem value="NORTH">NORTH</MenuItem>
                                    ) : user.user.id == 400 ? (
                                        <MenuItem value="SOUTH">SOUTH</MenuItem>
                                    ) : user.user.id == 402 ? (
                                        <MenuItem value="WEST">WEST</MenuItem>
                                    ) : (
                                        ""
                                    )} */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} xl={4} lg={4} md={4}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Select Start Date
                                    </InputLabel>
                                    <input
                                        type="date"
                                        name="StartDate"
                                        value={startDate}
                                        max={currentdate}
                                        onChange={(e) => handleChangeDate(e)}
                                        style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                                        className="form-control"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} xl={4} lg={4} md={4}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        Select End Date
                                    </InputLabel>
                                    <input
                                        type="date"
                                        label="From"
                                        name="EndDate"
                                        min={startDate}
                                        max={currentdate}
                                        value={endDate}
                                        onChange={(e) => handleChangeDate(e)}
                                        style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                                        className="form-control"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} xl={4} lg={4} md={4}>

                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => { generatePay() }}
                                    style={{ margin: "45px" }}
                                >
                                    Generate Report{" "}
                                </Button>
                            </Grid>
                        </div>
                    </Paper>
                    : ""
                }
                {user.user.roles == 20 ?
                    <>
                        <MaterialTable
                            columns={[
                                // { title: "SlNo", field: "SlNo" },
                                // { title: "Doctor Id", field: "doctorId" },
                                {
                                    title: "Doctor Name", field: "name",
                                },
                                { title: "Doctor Email", field: "email" },
                                { title: "Pan", field: "panno" },
                                {
                                    title: "Doctor Type", field: "category",
                                    lookup: {
                                        "Full Time Consultant": "Full Time Consultant",
                                        "Visiting Consultant": "Visiting Consultant",
                                        "Retainer": "Retainer",
                                        "Minimum Guarentee(MG)": "Minimum Guarentee(MG)",
                                    }
                                },
                                { title: "MG / Month", field: "minguranteepermonth", render: (row) => (row.minguranteepermonth ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "Rs. 0") },
                                { title: "Fixed", field: "fixedAmount", render: (row) => (row.fixedAmount ? "Rs. " + numberWithCommas(row.fixedAmount) : "Rs. 0") },
                                { title: "OPD", field: "OPPayout", render: (row) => (row.OPPayout ? "Rs. " + numberWithCommas(row.OPPayout) : "Rs. 0") },
                                { title: "IPD", field: "IPPayout", render: (row) => (row.IPPayout ? "Rs. " + numberWithCommas(row.IPPayout) : "Rs. 0") },
                                { title: "Payout", field: "Payout", render: (row) => (row.Payout ? "Rs. " + numberWithCommas(row.Payout) : "Rs. 0") },
                                {
                                    title: "MG Earning", field: "Payout", render: (row) => {
                                        if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) > row.minguranteepermonth) {
                                            return "Rs. " + numberWithCommas(((row.OPPayout + row.IPPayout) - row.minguranteepermonth))
                                        } else if (row.minguranteepermonth > 0 && (row.OPPayout + row.IPPayout) < row.minguranteepermonth) {
                                            return "Rs. -" + numberWithCommas((row.minguranteepermonth - (row.OPPayout + row.IPPayout)))
                                        } else {
                                            return "Rs. 0"
                                        }
                                    }
                                },
                            ]}
                            // editable={(payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_approval.state && user.user.roles == 7) ? {
                            //         onRowUpdate: (newData, oldData) =>
                            //             new Promise((resolve, reject) => {
                            //                 setTimeout(() => {
                            //                     const dataUpdate = [...TempData];
                            //                     const index = oldData.tableData.id;
                            //                     dataUpdate[index] = newData;
                            //                     console.log(oldData, newData, 'exception item update')
                            //                     if (newData.GLCoe == '') {
                            //                         setConfirmDialog({
                            //                             isOpen: true,
                            //                             title: "Alert",
                            //                             subtitle: "Please Add Remarks",
                            //                         });
                            //                         reject()
                            //                         return;
                            //                     } else {
                            //                         updateBillItemData(newData, oldData)
                            //                         setIPPayoutReports([...dataUpdate]);
                            //                         resolve();
                            //                     }

                            //                 }, 1000)
                            //             }),
                            //     } : {}}
                            data={TempData}
                            options={{
                                sorting: true,
                                // exportButton: true,
                                exportAllData: true,
                                filtering: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                search: true,
                            }}
                            icons={{
                                Filter: "filter",
                            }}
                            actions={[
                                {
                                    icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                    tooltip: "Export to Excel",
                                    onClick: () => downloadExcel(),
                                    isFreeAction: true
                                },
                                {
                                    icon: 'info',
                                    tooltip: 'View Details',
                                    onClick: (event, rowData) => getDoctorData(rowData)
                                }
                            ]}
                            onSelectionChange={(rows) => {
                                setRowSelection(true);
                                setChangeRowValue(rows);
                            }}
                            title="Payout Report"
                        />

                        {doctor && doctor.name && doctor.email ?
                            <>
                                <MaterialTable
                                    columns={[
                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                        {
                                            title: "IP No",
                                            field: "ip",
                                            editable: 'never',
                                        },
                                        { title: "Bill No", field: "BillNo", editable: 'never' },
                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                        { title: "Bill Time", field: "BillTime", editable: 'never' },
                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                        {
                                            title: "Age", field: "age", editable: 'never',
                                        },
                                        {
                                            title: "Gender", field: "gender", editable: 'never',
                                        },
                                        { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                        {
                                            title: "Admit Group", field: "admitGroup", editable: 'never',
                                        },
                                        {
                                            title: "Admission Purpose", field: "admissionPurpose", editable: 'never',
                                        },
                                        {
                                            title: "Admission Date", field: "AdmissionDate", editable: 'never',
                                        },
                                        {
                                            title: "Discharge Date", field: "DischargeDate", editable: 'never',
                                        },
                                        {
                                            title: "LOS(InDays)", field: "LOS", editable: 'never',
                                        },
                                        {
                                            title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                "IP Physician Consulting": "IP Physician Consulting",
                                                "OT Surgeon Charges": "OT Surgeon Charges",
                                                "Radiology": "Radiology",
                                                "Non OT Procedures": "Non OT Procedures",
                                                "Anesthesia Services": "Anesthesia Services",
                                                "Other Charges": "Other Charges",
                                                "Pharmacy": "Pharmacy",
                                                "Lab": "Lab",
                                                "Registration Charge": "Registration Charge",
                                                "Care Team": "Care Team",
                                                "Procedure Charge": "Procedure Charge",
                                                "Bed Charges": "Bed Charges",
                                            }
                                        },
                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                        {
                                            title: "Bed Category", field: "bedCategory", editable: 'never',
                                        },
                                        {
                                            title: "Bill Type", field: "billType", editable: 'never',
                                        },
                                        {
                                            title: "Package Name", field: "packageName", editable: 'never',
                                        },
                                        {
                                            title: "Package Amount", field: "PackageAmount", editable: 'never',
                                            render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                                        },
                                        {
                                            title: "Category", field: "insuranceCategory", editable: 'never',
                                        },
                                        {
                                            title: "TPA", field: "tpaName", editable: 'never',
                                        },
                                        {
                                            title: "Insurance", field: "insurance", editable: 'never',
                                        },
                                        {
                                            title: "Corporate Name", field: "organisation", editable: 'never',
                                        },
                                        { title: "User", field: "User", editable: 'never' },
                                        { title: "Month", field: "PaymentMonth", editable: 'never' },
                                        {
                                            title: "ERP LOS", field: "erpLos", editable: 'never',
                                            lookup: {
                                                "Gynec": "Gynec",
                                                "Delivery": "Delivery",
                                                "Pead": "Pead",
                                                "Fertility": "Fertility",
                                            },
                                        },
                                        {
                                            title: "Pharmacy", field: "PharmacyTotal", editable: 'never',
                                            render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                                        },
                                        { title: "Discount", field: "Discount", editable: 'never' },
                                        {
                                            title: "Lab", field: "Lab", editable: 'never',
                                            render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                                        },
                                        {
                                            title: "Radiology", field: "Radiology", editable: 'never',
                                            render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                                        },
                                        {
                                            title: "Other Deduction", field: "otherDeduction", editable: 'never',
                                            render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                                        },
                                        { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                        {
                                            title: "Package Inclusion", field: "packageInclusion",
                                        },
                                        { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                        {
                                            title: "Nature", field: "NatureofDoctors",
                                        },
                                        {
                                            title: "Terms", field: "commercialTerm",
                                        },
                                        { title: "Qty", field: "Count" },
                                        { title: "Amount", field: "doctorAmount" },
                                        { title: "Ratio", field: "Ratio" },
                                        { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                        { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                        // { title: "RD Remarks ", field: "Concatenate" },
                                    ]}
                                    data={iPDetails}
                                    options={{
                                        sorting: true,
                                        // exportButton: true,
                                        exportAllData: true,
                                        fixedColumns: {
                                            top: 1,
                                            right: 0
                                        },
                                        maxBodyHeight: '500px',
                                        filtering: true,
                                        // filterRowStyle: {
                                        //     position: "sticky",
                                        //     top: 60,
                                        //     background: "white",
                                        //     zIndex: 5 /* optionally */
                                        // },
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                        search: true,
                                    }}
                                    icons={{
                                        Filter: "filter",
                                    }}
                                    actions={[
                                        {
                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                            tooltip: "Export to Excel",
                                            onClick: () => downloadDoctorIPReport(),
                                            isFreeAction: true
                                        }
                                    ]}
                                    onSelectionChange={(rows) => {
                                        setRowSelection(true);
                                        setChangeRowValue(rows);
                                    }}
                                    title={"Doctor IP Payout Report " + (doctor && doctor.name ? doctor.name : "")}
                                />
                                <MaterialTable
                                    columns={[
                                        // {
                                        //   title: "Split",
                                        //   field: "Action",
                                        //   filtering: false,
                                        //   hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                                        //   render: (row) => (
                                        //     <Tooltip title="Split Record">
                                        //       <IconButton>
                                        //         <CallSplitIcon
                                        //           style={{
                                        //             transform: "rotate(90deg)",
                                        //             color: "#810e36",
                                        //           }}
                                        //           onClick={() => {
                                        //             setSplitDialog(true);
                                        //             onSplitRow(row);
                                        //           }}
                                        //         />
                                        //       </IconButton>
                                        //     </Tooltip>
                                        //   ),
                                        // },
                                        { title: "MPI", field: "MPI", editable: 'never', render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                        { title: "Bill No", field: "BillNo", editable: 'never' },
                                        { title: "Bill Date", field: "BillDate", editable: 'never' },
                                        { title: "Bill Time", field: "BillTime", editable: 'never' },
                                        { title: "Patient Name", field: "PatientName", editable: 'never' },
                                        { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                                        { title: "Specialisation", field: "Specialisation", editable: 'never' },
                                        {
                                            title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                                                "OP Physician Consulting": "OP Physician Consulting",
                                                "Radiology": "Radiology",
                                                "Non OT Procedures": "Non OT Procedures",
                                                "Other Charges": "Other Charges",
                                                "Pharmacy": "Pharmacy",
                                                "Lab": "Lab",
                                                "Registration Charge": "Registration Charge",
                                                "Medical Equipments": "Medical Equipments",
                                                "Cryonine-Stem Cell": "Cryonine-Stem Cell",
                                                "Ear Piercing - Product": "Ear Piercing - Product",
                                                "Ear Piercing - Service": "Ear Piercing - Service",
                                            }
                                        },
                                        { title: "Item Name", field: "ItemName", editable: 'never' },
                                        { title: "User", field: "User", editable: 'never' },
                                        { title: "Month", field: "PaymentMonth", editable: 'never' },
                                        { title: "Discount", field: "Discount", editable: 'never' },
                                        { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                                        { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                                        {
                                            title: "Terms", field: "commercialTerm",
                                        },
                                        { title: "Qty", field: "Count" },
                                        { title: "Amount", field: "doctorAmount" },
                                        { title: "Ratio", field: "Ratio" },
                                        { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                                        { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
                                        { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                                        // { title: "RD Remarks ", field: "Concatenate" },
                                    ]}
                                    data={oPDetails}
                                    options={{
                                        sorting: true,
                                        // exportButton: true,
                                        exportAllData: true,
                                        fixedColumns: {
                                            top: 1,
                                            right: 0
                                        },
                                        maxBodyHeight: '500px',
                                        filtering: true,
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                        search: true,
                                    }}
                                    icons={{
                                        Filter: "filter",
                                    }}
                                    actions={[
                                        {
                                            icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                                            tooltip: "Export to Excel",
                                            onClick: () => downloadDoctorOPReport(),
                                            isFreeAction: true
                                        }
                                    ]}
                                    onSelectionChange={(rows) => {
                                        setRowSelection(true);
                                        setChangeRowValue(rows);
                                    }}
                                    title={"Doctor OP Payout Report " + (doctor && doctor.name ? doctor.name : "")}
                                />
                            </>
                            :
                            ""
                        }
                        {
                            payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.init.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_approval.state && user.user.roles == 7 ?
                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => sendToCH()}
                                    >
                                        Send to center head
                                    </Button>
                                </div>
                                :
                                payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.ch_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.ch_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.roles == 2 ?
                                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => sendToDPReview()}
                                        >
                                            Send to DP Team
                                        </Button>
                                    </div>
                                    :
                                    payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_second_approval.state && user.user.roles == 7 ?
                                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => sendToCOO()}
                                            >
                                                Send to COO
                                            </Button>
                                        </div>
                                        :
                                        payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.rd_approval.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.rd_approval.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.rd_approval.state && user.user.roles == 1 ?
                                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => sendToDP()}
                                                >
                                                    Approved
                                                </Button>
                                            </div>
                                            :
                                            payoutDataStatus && payoutDataStatus.IPPayoutDataStatus && payoutDataStatus.IPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.OPPayoutDataStatus && payoutDataStatus.OPPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && payoutDataStatus.RetainerPayoutDataStatus && payoutDataStatus.RetainerPayoutDataStatus.nextAction == payoutState.dp_team_final_reviw.state && user.user.roles == 7 ?
                                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        size="small"
                                                        color="secondary"
                                                        variant="contained"
                                                    // onClick={() => sendToDP()}
                                                    >
                                                        Send TO D365
                                                    </Button>
                                                </div>
                                                : ""
                        }
                    </>
                    :
                    ""
                }

            </Container>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    setSplitDialog(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={splitDialog}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerClose}
                    style={{ textAlign: "center" }}
                >
                    {spitRecord.ConductedBy}
                </DialogTitle>
                <Grid container>
                    <Grid item xs={12} xl={6} md={6} lg={6} sm={12}>
                        <DialogContent dividers>
                            <Typography>MPI: {spitRecord.MPI}</Typography>
                            <Typography>BillDate: {spitRecord.BillDate}</Typography>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Select Doctor Name
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="doctorname"
                                    value={rowDetail.doctorname}
                                    onChange={handleInputChange}
                                    label="  Select Doctor Name"
                                >
                                    {payOutCat &&
                                        ((payOutCat).filter((el) => el.siteCode == center)).map((opt) => (
                                            <MenuItem key={opt.id} value={opt.name}>
                                                {opt.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <label>
                                <b>Ratio</b>
                            </label>
                            <TextField
                                id="Payout"
                                label="Ratio"
                                variant="outlined"
                                fullWidth
                                name="ratio"
                                value={rowDetail.ratio}
                                onChange={handleInputChange}
                                style={{ marginBottom: "20px" }}
                                autoComplete="off"
                            />
                        </DialogContent>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        xl={6}
                        md={6}
                        lg={6}
                        sm={12}
                        style={{ height: "300px", overflowY: "auto" }}
                    >
                        <MaterialTable
                            columns={[
                                { title: "SlNo", field: "SlNo" },
                                { title: "MPI", field: "MPI", render: (row) => row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", "") },
                                { title: "Bill No", field: "BillNo" },
                                { title: "Patient Name", field: "PatientName" },
                                { title: "ConductedBy", field: "ConductedBy" },
                                { title: "Share", field: "Share" },
                                { title: "Item Group Name", field: "ItemGroupName" },
                                { title: "Item Name", field: "ItemName" },
                                { title: "Gross", field: "Gross" },
                                { title: "Net", field: "Net" },
                            ]}
                            data={splitNewRec}
                            title="Split Rows"
                        />
                    </Grid>
                </Grid>

                <Button
                    color="secondary"
                    onClick={() => {
                        addRowData(spitRecord);
                    }}
                >
                    Add
                </Button>
                <DialogActions>
                    {splitNewRec && splitNewRec.length > 0 ? (
                        <Button
                            autoFocus
                            onClick={() => {
                                changeSplitRecord();
                            }}
                            color="secondary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    ) : (
                        ""
                    )}

                    <Button
                        autoFocus
                        onClick={() => {
                            setSplitDialog(false);
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                className={backClasses.backdrop}
                open={backDropOpen}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop className={backClasses.backdrop} open={circularLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            {/* <Card data={opt} */}
        </>
    );
}


